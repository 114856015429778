import React from "react";
import { useAuth } from "../../contexts/auth";
import "./styles.css";

export const SearchResults = ({
  friend_name,
  setSearch,
  setCredentials,
  credentials,
  id,
  type = "event",
  setSelectedUserData
}) => {
  const auth = useAuth();

  const handleSearchClick = (name, id) => {
    if(setSelectedUserData !== undefined){
      setSelectedUserData(name)
    }
    if (type === "event") {
      console.log(id);
      setCredentials({ ...credentials, user_name: name, from: id });
      console.log({ ...credentials, user_name: name, from: id });
      setSearch("");
    }
    if (type === "add_friend") {
      console.log(id);
      setCredentials({
        ...credentials,
        follower_id: auth.user.ID,
        user_id: id,
        relation_type: "request",
      });
      console.log({
        ...credentials,
        follower_id: auth.user.ID,
        user_id: id,
        relation_type: "request",
      });
      setSearch("");
    }
  };

  return (
    <div>
      {friend_name === "none" ? (
        <div className="empty-results">No se encontraron resultados.</div>
      ) : (
        <div
          className="search-result"
          onClick={() => handleSearchClick(friend_name, id)}
        >
          {friend_name}
        </div>
      )}
      <div className="separator-line"></div>
    </div>
  );
};
