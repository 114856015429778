import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { DEFAULT_PROFILE } from "../../utils/state";
import InputImage from "../../components/InputImage";
import TextInput from "../../components/TextInput";
import "./styles.css";
import Layout from "../../components/Layout";

export const EditarPerfil = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [credentials, setCredentials] = React.useState(DEFAULT_PROFILE);

  React.useEffect(() => {
    if (auth.user) {
      let temp = "";
      temp = auth.user.user_display_name.split(" ");
      setCredentials({
        name: temp[0],
        lastName: temp[1],
        birthday: "2-11-1997",
      });
      console.log(credentials);
    }
    // eslint-disable-next-line
  }, [auth.user]);

  const handleSave = () => {
    navigate("/mi-perfil");
  };

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
    console.log(credentials);
  };

  return (
    <Layout>
      <div className="p-3">
        <h1 className="titulo-mi-perfil">Editar Perfil</h1>
        <InputImage n={0} />
        <TextInput
          name={"name"}
          onChange={handleChange}
          label={"Nombre"}
          type={"text"}
          placeHolder={credentials.name}
        />
        <TextInput
          name={"lastName"}
          onChange={handleChange}
          label={"Apellido"}
          type={"text"}
          placeHolder={credentials.lastName}
        />
        <TextInput
          name={"birthday"}
          onChange={handleChange}
          label={"Cumpleaños"}
          type={"text"}
          placeHolder={credentials.birthday}
        />
        <div type="button" className="save-profile" onClick={handleSave}>
          Guardar cambios
        </div>
      </div>
    </Layout>
  );
};
