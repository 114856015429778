import React from 'react'
import Layout from '../../components/Layout'
import Help from '../../components/Help'

const Ayuda = () => {
  return (
    <Layout>
      <Help />
    </Layout>
  )
}

export default Ayuda