import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/auth";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SnackProvider } from "./contexts/snack";

ReactDOM.createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider clientId="53915227-bac58s0oj0ml6i8qsod2nsuvl8pssikk.apps.googleusercontent.com">
    <AuthProvider>
      <SnackProvider>
        <App />
      </SnackProvider>
    </AuthProvider>
  </GoogleOAuthProvider>
);
reportWebVitals();
