import React from "react";
import "./styles.css";

export const NavbarPublic = () => {
  return (
    <div className="navbar-public-box">
      <div className="navbar-public">
        <img
          src={require("../../assets/images/logo-giftoky-blanco.png")}
          alt="logo giftoky"
        />
        <div className="rr-ss">
          {/* <img
            src={require("../../assets/images/facebook.png")}
            alt="red social"
          />
          <img
            src={require("../../assets/images/instagram.png")}
            alt="red social"
          /> */}
        </div>
      </div>
    </div>
  );
};
