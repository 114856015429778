import React from "react";
import { useNavigate } from "react-router-dom";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import { changePassword } from "../../services/tipddy";
import { DEFAULT_RECOVERY } from "../../utils/state";
import TextInput from "../TextInput";

const RecoveryComponent = () => {
  const [credentials, setCredentials] = React.useState(DEFAULT_RECOVERY);
  const navigate = useNavigate();
  const [, handleSnack] = useSnack();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = await changePassword(credentials);

    console.log(data);

    if (data.data.status === 200) {
      handleSnack({
        type: SNACKTYPES.success,
        payload: {
          message: data.message,
        },
      });

      navigate("/login");
    } else {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Solicitud inválida",
        },
      });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <div className="d-flex">
      <div className="img-login">
        <img
          src={require("../../assets/images/foto-login3.jpg")}
          alt="imagen login"
        />
      </div>
      <div className="form-container-login">
        <form className="form-login">
          <img
            className="logo-login"
            src={require("../../assets/images/logo-net.png")}
            alt="logo giftoky"
            onClick={() => navigate("/login")}
          />
          <TextInput
            name={"email"}
            onChange={handleChange}
            label={"Ingrese su correo"}
            type={"email"}
            ancho={true}
          />
          <TextInput
            name={"code"}
            onChange={handleChange}
            label={"Ingrese nuevamente el código"}
            type={"number"}
            ancho={true}
          />
          <TextInput
            name={"password"}
            onChange={handleChange}
            label={"Ingrese su nueva contraseña"}
            type={"password"}
            ancho={true}
          />
          <button
            type="submit"
            className="btn-giftoky ancho-login"
            onClick={(e) => handleSubmit(e)}
          >
            Cambiar contraseña
          </button>
        </form>
      </div>
    </div>
  );
};

export default RecoveryComponent;
