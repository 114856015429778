import React from "react";
import "./styles.css";

const TarjetaPerfil = ({ datos }) => {
  return (
    <div className="row align-items-center tarjeta-mi-perfil">
      <div className="col-2">
        <img
          src={require("../../assets/images/usuario1.jpg")}
          alt="foto perfil personalizada"
          className="foto-perfil"
        />
      </div>
      <div className="col-6">
        <p className="nombre-tarjeta">
          {datos.name} {datos.lastName}
        </p>
        <p className="p-tarjeta">Cumpleaños {datos.birthday}</p>
        <p className="p-tarjeta mt-3">87% del perfil completado</p>
      </div>
      <div className="col-4">
        <p className="p-tarjeta">Hoy, jueves 16 de enero</p>
        <p className="p-tarjeta">Faltan 22 días para mi cumpleaños</p>
      </div>
    </div>
  );
};

export default TarjetaPerfil;
