import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const TarjetaMisAmigos = ({ datos }) => {
  const navigate = useNavigate();

  return (
    <div className="tarjeta-mis-amigos align-items-center">
      <div className="col-2">
        <img
          src={
            datos.user_profile_image
              ? datos.user_profile_image
              : require(`../../assets/images/usuario1.jpg`)
          }
          alt="imagen perfil amigo"
          className="foto-perfil"
        />
      </div>
      <div className="col-4">
        <p className="p-nombre-amigo">
          {datos.name} {datos.lastName}
        </p>
        <p className="p-tarjeta-amigo">
          {/* {datos.num_wishes.length}{" "}
          {datos.num_wishes.length === 1 ? "deseo" : "deseos"} */}
        </p>
      </div>
      <div className="col-6 text-end" style={{ paddingRight: "30px" }}>
        {/* <p className="p-tarjeta-amigo">Sin Regalo, !Reservalo ahora!</p> */}
        <img
          src={require("../../assets/images/vista.png")}
          alt="imagen ver perfil"
          onClick={() => navigate(`/ficha-contacto?user_id=${datos.ID}`)}
        />
      </div>
    </div>
  );
};

export default TarjetaMisAmigos;
