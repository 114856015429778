import React from "react";
import Layout from "../../components/Layout";
import "./styles.css"

const InviteReferrals = () => {
  return (
    <Layout>
      <div className="p-3">
        <h2>Invitar y Referidos</h2>
        <h3>Referidos</h3>
        <p className="p-policy">
          Regístrate ahora, invita a tus amig@s y si consigues 5 usuarios
          referidos, te regalamos anotar 100 deseos adicionales en tu lista. Si
          Logras 5 más son ilimitados! (Actualmente tienes un máximo de 20 item
          para agregar a "Mis Deseos")
        </p>
        <button type="button" className="btn-giftoky btn-invite">Registrarme gratis!</button>
        <h3>Invitados</h3>
        <p className="p-policy">
          Después de registrarte, si uno de tus invitados se une a Giftoky e
          indica tu e-mail, te avisaremos por la misma via que ya es uno de tus
          referidos. Te ayudamos con un pequieño empujón para entusiasmarlo: Al
          inscribirse pronto, le etregamos para siempre anotar 10 deseos
          adicionales en la lista "Mis Deseos" gratis! :)
        </p>
        <button type="button" className="btn-giftoky btn-invite">Invital@s ahora</button>
      </div>
    </Layout>
  );
};

export default InviteReferrals;
