import { SERVER_URL } from "../api";
import axios from "axios";

const jsonHeaders = { "Content-type": "application/json" };

export const registerUser = async (payload) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}/v2/public/register/user`,
      payload,
      { headers: jsonHeaders }
    );
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const authenticate = async (payload) => {
  try {
    let data = await axios.post(`${SERVER_URL}/jwt-auth/v1/token`, payload, {
      headers: jsonHeaders,
    });
    return data;
  } catch (err) {
    return err;
  }
};

export const sendEmailForgotPassword = async (payload) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}/bdpwr/v1/reset-password`,
      payload,
      { headers: jsonHeaders }
    );
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const checkCodeForRecovery = async (payload) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}/bdpwr/v1/validate-code`,
      payload,
      {
        headers: jsonHeaders,
      }
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const changePassword = async (payload) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}/bdpwr/v1/set-password`,
      payload,
      {
        headers: jsonHeaders,
      }
    );
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const createGift = async (payload, token) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}/v2/public/register/wish`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateGift = async (payload, token) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}/v2/public/update/wish`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.result;
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteGift = async (id, token) => {
  try {
    let { data } = await axios.delete(`${SERVER_URL}/v2/public/wish/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getGiftByID = async (id, token) => {
  try {
    let { data } = await axios.get(`${SERVER_URL}/v2/public/wish/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.result;
  } catch (e) {
    throw new Error(e);
  }
};

export const createEvent = async (payload, token) => {
  try {
    let { data } = await axios.post(
      `${SERVER_URL}/v2/public/register/event`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteEvent = async (id, token) => {
  try {
    let { data } = await axios.delete(`${SERVER_URL}/v2/public/event/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getEventByID = async (id, token) => {
  try {
    let { data } = await axios.get(`${SERVER_URL}/v2/public/event/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.result;
  } catch (e) {
    throw new Error(e);
  }
};

export const updateEvent = async (payload, token) => {
  try {
    let data = await axios.put(
      `${SERVER_URL}/v2/public/update/event`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.result;
  } catch (e) {
    throw new Error(e);
  }
};

export const listGifts = async (token) => {
  try {
    let { data } = await axios.get(`${SERVER_URL}/v2/public/wishes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(data);
    return data.result;
  } catch (err) {
    console.log("listGifts err:", err);
    return err.response.status;
    // throw new Error(err);
  }
};

export const listEvents = async (token) => {
  try {
    let { data } = await axios.get(`${SERVER_URL}/v2/public/events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.result;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const listGiftokers = async (token) => {
  try {
    let { data } = await axios.get(`${SERVER_URL}/v2/public/contacts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const listFollowers = async (token) => {
  try {
    let { data } = await axios.get(
      `${SERVER_URL}/v2/public/contacts/followers`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const listIFollowed = async (token) => {
  try {
    let { data } = await axios.get(
      `${SERVER_URL}/v2/public/isent/contacts/followers`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const listRequests = async (token) => {
  try {
    let { data } = await axios.get(`${SERVER_URL}/v2/public/contacts/request`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const listIRequests = async (token) => {
  try {
    let { data } = await axios.get(
      `${SERVER_URL}/v2/public/isent/contacts/request`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const getUserPublicInformation = async (userid, token) => {
  try {
    let { data } = await axios.get(
      `${SERVER_URL}/v2/public/info/user/${userid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data.result;
  } catch (err) {
    console.log(err);
    return err.response.status;
    // throw new Error(err);
  }
};

export const getUserByParam = async (payload, token) => {
  try {
    let { data } = await axios.get(
      `${SERVER_URL}/v2/public/contacts?search=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data.result;
  } catch (e) {
    throw new Error(e);
  }
};

export const sendFollowerRequest = async (payload, token) => {
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/v2/public/register/relation`,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const updateUser = async (token, payload) => {
  try {
    let { data } = await axios.put(
      `${SERVER_URL}/v2/public/register/user`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const cancelFollowerRequest = async (user_id, token) => {
  try {
    let res = await fetch(`${SERVER_URL}/v2/public/register/relation`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        relation_type: "request",
      }),
    });
    let data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const cancelFollowerIRequest = async (user_id, token) => {
  try {
    let res = await fetch(`${SERVER_URL}/v2/public/register/relation`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        relation_type: "request",
        sent: 1,
      }),
    });
    let data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteFollower = async (user_id, token) => {
  try {
    let res = await fetch(`${SERVER_URL}/v2/public/register/relation`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        relation_type: "follower",
      }),
    });
    let data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteFollowed = async (user_id, token) => {
  try {
    let res = await fetch(`${SERVER_URL}/v2/public/register/relation`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        relation_type: "follower",
        sent: 1,
      }),
    });
    let data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const confirmFollowerRequest = async (user_id, token) => {
  try {
    const { data } = await axios.put(
      `${SERVER_URL}/v2/public/update/relation`,
      {
        user_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const verifyReservedGift = async (id, token) => {
  try {
    const { data } = await axios.get(
      `${SERVER_URL}/v2/public/follower/wish/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {}
};

export const giftReservation = async (id, token) => {
  try {
    const { data } = await axios.post(
      `${SERVER_URL}/v2/public/follower/wish/`,
      {
        wish_id: id,
        date: new Date(),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {}
};

export const cancelReservedGift = async (id, token) => {
  try {
    let res = await fetch(`${SERVER_URL}/v2/public/follower/wish/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        wish_id: id,
      }),
    });
    let data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
};
