import React from "react";
import ListaDeseos from "../../components/ListaDeseos";
import Layout from "../../components/Layout";

export const MisDeseos = () => {
  return (
    <Layout>
      <ListaDeseos />
    </Layout>
  );
};
