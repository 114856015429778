import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes/routes";
import "./App.css";
import { useAuth } from "./contexts/auth";
import { PrivateRoute } from "./routes/protected.route";
import { Snackbar } from "./components/SnackBar";
import { useSnack } from "./contexts/snack";

function App() {
  const auth = useAuth();
  const [snack] = useSnack();
  React.useEffect(() => {
    if (!auth.user) {
      auth.VerifyAuth();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            const Element = route.requireAuth ? (
              <PrivateRoute>
                <route.element />
              </PrivateRoute>
            ) : (
              <route.element />
            );
            return <Route path={route.path} element={Element} key={index} />;
          })}
        </Routes>
      </BrowserRouter>
      <Snackbar {...snack} />
    </>
  );
}

export default App;
