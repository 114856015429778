import FichaAmigo from "../pages/FichaAmigo";
import InviteReferrals from "../pages/InviteReferrals";
import Login from "../pages/Login";
import MiPerfil from "../pages/MiPerfil";
import MisAmigos from "../pages/MisAmigos";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Register from "../pages/Register";
import Ayuda from "../pages/Ayuda";
import AgregarDeseo from "../pages/AgregarDeseo";
import RecoveryPassword from "../pages/RecoveryPassword";
import ForgotPassword from "../pages/ForgotPassword";
import { Mensajes } from "../pages/Mensajes";
import { EditarPerfil } from "../pages/EditarPerfil";
import { AgregarEvento } from "../pages/AgregarEvento";
import { CheckRecoveryCode } from "../pages/CheckRecoveryCode";
import { MisDeseos } from "../pages/MisDeseos";
import { MensajesAmigos } from "../pages/MensajesAmigos";
import { AgregarAmigo } from "../pages/AgregarAmigo";
import { CompartirRegalo } from "../pages/Compartir/gift";
import { CompartirRegalos } from "../pages/Compartir/gifts";
import { CompartirUsuario } from "../pages/Compartir/user";

export const routes = [
  {
    path: "/",
    element: MisDeseos,
    requireAuth: true,
  },
  {
    path: "/mis-contactos",
    element: MisAmigos,
    requireAuth: true,
  },
  {
    path: "/mi-perfil",
    element: MiPerfil,
    requireAuth: true,
  },
  {
    path: "/politicas-de-privacidad",
    element: PrivacyPolicy,
    requireAuth: true,
  },
  {
    path: "/ficha-contacto",
    element: FichaAmigo,
    requireAuth: true,
  },
  {
    path: "/login",
    element: Login,
    requireAuth: false,
  },
  {
    path: "/invitar-referidos",
    element: InviteReferrals,
    requireAuth: true,
  },
  {
    path: "/ayuda",
    element: Ayuda,
    requireAuth: true,
  },
  {
    path: "/registrar",
    element: Register,
    requireAuth: false,
  },
  {
    path: "/agregar-deseo",
    element: AgregarDeseo,
    requireAuth: true,
  },
  {
    path: "/forgot",
    element: ForgotPassword,
    requireAuth: false,
  },
  {
    path: "/check-code",
    element: CheckRecoveryCode,
    requireAuth: false,
  },
  {
    path: "/recovery",
    element: RecoveryPassword,
    requireAuth: false,
  },
  {
    path: "/messages",
    element: Mensajes,
    requireAuth: true,
  },
  {
    path: "/contact-messages",
    element: MensajesAmigos,
    requireAuth: true,
  },
  {
    path: "/edit-profile",
    element: EditarPerfil,
    requireAuth: true,
  },
  {
    path: "/agregar-evento",
    element: AgregarEvento,
    requireAuth: true,
  },
  {
    path: "/agregar-contacto",
    element: AgregarAmigo,
    requireAuth: true,
  },
  {
    path: "/share/gifts",
    element: CompartirRegalos,
    requireAuth: false,
  },
  {
    path: "/share/gift",
    element: CompartirRegalo,
    requireAuth: false,
  },
  {
    path: "/share/user",
    element: CompartirUsuario,
    requireAuth: false,
  },
];
