import React from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAuth } from "../../contexts/auth";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import {
  deleteGift,
  listGifts,
  verifyReservedGift,
} from "../../services/tipddy";
import AlertDialog from "../AlertDialog";
import HeaderMisDeseos from "../HeaderMisDeseos";
import { Loader } from "../Loader";
import "./styles.css";

const ListaDeseos = () => {
  const [deseos, setDeseos] = React.useState([]);
  const [reservedGift, setReservedGift] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [wishDeleted, setWishDeleted] = React.useState(0);
  const [idsChecked, setIdsChecked] = React.useState([]);
  const [, handleSnack] = useSnack();
  const auth = useAuth();
  const navigate = useNavigate();

  const handleFilter = (e) => {
    if (e.value === "nameAZ") {
      const sortedName = [...deseos].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setDeseos(sortedName);
      return;
    }
    if (e.value === "nameZA") {
      const sortedName = [...deseos].sort((a, b) =>
        b.name.localeCompare(a.name)
      );
      setDeseos(sortedName);
      return;
    }
    if (e.value === "priceIncreasing") {
      const sortedPrice = [...deseos].sort((a, b) => a.price - b.price);
      setDeseos(sortedPrice);
      return;
    }
    if (e.value === "priceDecreasing") {
      const sortedPrice = [...deseos].sort((a, b) => b.price - a.price);
      setDeseos(sortedPrice);
      return;
    }
  };

  const options = [
    { value: "", label: "--Elija una Opción--" },
    { value: "nameAZ", label: "Nombre A-Z" },
    { value: "nameZA", label: "Nombre Z-A" },
    { value: "priceIncreasing", label: "Precio menor a mayor" },
    { value: "priceDecreasing", label: "Precio mayor a menos" },
  ];

  const handleSeeMore = (id) => {
    navigate(`/agregar-deseo?${id}`);
  };

  const handleCheckBox = (e) => {
    let array = [...idsChecked];
    if (e.target.checked) {
      setIdsChecked([...idsChecked, e.target.id]);
    } else {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === e.target.id) {
          array.splice(array.indexOf(e.target.id), 1);
        }
      }
      setIdsChecked(array);
    }
  };

  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    if (idsChecked.length === 0) {
      setOpen(false);
      return handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "No tiene deseos seleccionados",
        },
      });
    }

    setLoader(true);
    idsChecked.forEach(
      async (element) => await deleteGift(element, auth.user.token)
    );
    setOpen(false);
    setWishDeleted(wishDeleted + 1);
    handleSnack({
      type: SNACKTYPES.success,
      payload: {
        message: "Deseos borrados con éxito",
      },
    });
    setLoader(false);
  };

  const handleShare = () => {
    alert("share");
  };

  React.useEffect(() => {
    setIdsChecked([]);
    setLoader(true);
    let wishData = async () => {
      let data = await listGifts(auth.user.token);
      let newArray = [];
      data.map((e) => {
        let obj = {
          name: e.title,
          price: e.price,
          id: e.ID,
        };
        newArray.push(obj);
        return newArray;
      });
      setLoader(false);
      setDeseos(newArray);
      return data;
    };
    wishData();
    let borderInput = document.querySelector(".css-1s2u09g-control");
    let placeholderInput = document.querySelector(".css-14el2xx-placeholder");
    borderInput.style.border = "none";
    borderInput.style.boxShadow = "none";
    placeholderInput.style.color = "#981E97";
    // eslint-disable-next-line
  }, [wishDeleted]);

  React.useEffect(() => {
    if (auth.user) {
      verifyReservedGift(auth.user.ID, auth.user.token).then((res) => {
        setReservedGift(res.result);
      });
    }
  }, [auth.user]);

  return (
    <div className="p-3">
      <HeaderMisDeseos
        setOpen={setOpen}
        idsChecked={idsChecked}
        handleShare={handleShare}
        reservedGift={reservedGift}
      />
      <div style={{ marginBottom: "1rem" }}>Ordenar Por:</div>
      <Select
        onChange={handleFilter}
        options={options}
        placeholder="Elija una opción"
      />
      <div className="filete"></div>
      <Loader visible={loader} />
      <ul className="px-0">
        {deseos.map((wish, idx) => {
          return (
            <li
              key={idx}
              className="d-flex align-items-center justify-content-between py-3 wish-item-list"
            >
              <div className="row" style={{ width: "70%" }}>
                <div className="col-1">
                  <input
                    className="form-check-input baj"
                    type="checkbox"
                    value={wish.name}
                    id={wish.id}
                    onChange={(e) => handleCheckBox(e)}
                  />
                </div>
                <div className="col-11">
                  <h5>{wish.name}</h5>
                </div>
              </div>
              <div className="row" style={{ width: "30%" }}>
                <div className="col-6">${wish.price}</div>
                <div
                  className="col-6"
                  type="button"
                  id={wish.id}
                  onClick={() => handleSeeMore(wish.id)}
                >
                  <img
                    src={require("../../assets/images/vista.png")}
                    alt="imagen boton +"
                  />
                </div>
                {/* <div
                  className="col-4"
                  type="button"
                  onClick={() => alert("view agregar deseo")}
                >
                  <img
                    src={require("../../assets/images/puntos.png")}
                    alt="imagen boton +"
                  />
                </div> */}
              </div>
            </li>
          );
        })}
      </ul>
      <AlertDialog
        open={open}
        title="¿Desea eliminar los deseos seleccionados?"
        body={""}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        bgColor={"red"}
      />
    </div>
  );
};

export default ListaDeseos;
