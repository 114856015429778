import React from "react";
import { useNavigate } from "react-router-dom";
import addButton from "../../assets/images/bot-mas.png";
import editButton from "../../assets/images/ico-editar.png";
import "./styles.css";

const HeaderMisDeseos = ({ setOpen, handleShare, reservedGift }) => {
  const [optionButton, setOptionButton] = React.useState(false);
  const navigate = useNavigate();
  const optionMenu = React.useRef(null);

  React.useEffect(() => {
    if (optionButton) {
      optionMenu.current.style.display = "unset";
    } else {
      optionMenu.current.style.display = "none";
    }

    console.log(optionButton);
  }, [optionButton]);

  return (
    <div className="row align-items-center my-3 ms-2 header header-mis-deseos">
      <div className="col-3 d-flex align-items-center">
        {/* <img className="mb-3" src={require('../../assets/images/ico-estrella.png')} alt="foto estrella"/> */}
        <h4>Mis Deseos</h4>
      </div>
      <div className="col-3">
        <button className="mis-preferencias">
          <div>Mis mensajes</div>
          {reservedGift.length > 0 ? (
            <div className="medal-btn text-center">{reservedGift.length}</div>
          ) : (
            <div style={{ visibility: "hidden" }}></div>
          )}
        </button>
      </div>
      <div className="col-3 d-flex align-items-center justify-content-end pe-3">
        <div className="pe-3">Agregar Deseo</div>
        <div type="button" onClick={() => navigate("/agregar-deseo")}>
          <img src={addButton} alt="imagen boton +" />
        </div>
      </div>
      <div className="col-3 d-flex align-items-center justify-content-end pe-3">
        <div className="pe-3">Editar</div>
        <div
          className="edit-mass-btn"
          type="button"
          onClick={() => setOptionButton(!optionButton)}
        >
          <img alt="imagen boton +" src={editButton} />
        </div>
        <div ref={optionMenu} className="options-menu">
          <div className="option-item" onClick={() => setOpen(true)}>
            Eliminar
          </div>
          <div className="option-item" onClick={handleShare}>
            Compartir
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMisDeseos;
