import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const HeaderMiPerfil = ({ datos }) => {
  const navigate = useNavigate();

  return (
    <div className="row align-items-center my-3 ms-3 header">
      <div className="col-6 d-flex align-items-center">
        <h1 className="titulo-mi-perfil">
          ¡Hola {datos.name} {datos.lastName}!
        </h1>
        <button
          type="button"
          className="preferencias-reg ms-3 mb-2"
          onClick={() => navigate("/messages")}
        >
          <img
            src={require("../../assets/images/ico-mail.png")}
            alt="foto mensajes"
            className="img-mail"
          />
          ¡Mensajes!
        </button>
      </div>
      <div className="col-3"></div>
      <div className="col-3 d-flex align-items-center justify-content-around">
        <div>Editar Perfil</div>
        <div type="button" onClick={() => navigate("/edit-profile")}>
          <img
            src={require("../../assets/images/ico-editar.png")}
            alt="imagen boton +"
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderMiPerfil;
