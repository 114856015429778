import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import { deleteEvent, listEvents } from "../../services/tipddy";
import AlertDialog from "../../components/AlertDialog";
import HeaderMiPerfil from "../../components/HeaderMiPerfil";
import { Loader } from "../../components/Loader";
import TarjetaPerfil from "../../components/TarjetaPerfil";
import TarjetaProximoEvento from "../../components/TarjetaPoximoEvento";
import Layout from "../../components/Layout";
import "./styles.css";

const MiPerfil = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [, handleSnack] = useSnack();
  const [userData, setUserData] = React.useState({});
  const [nextEvents, setNextEvents] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [idsChecked, setIdsChecked] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [eventDeleted, setEventDeleted] = React.useState(0);
  const [optionButton, setOptionButton] = React.useState(false);
  const optionMenu = React.useRef(null);

  React.useEffect(() => {
    if (optionButton) {
      optionMenu.current.style.display = "unset";
    } else {
      optionMenu.current.style.display = "none";
    }

    console.log(optionButton);
  }, [optionButton]);

  React.useEffect(() => {
    if (auth.user) {
      let temp = "";
      temp = auth.user.user_display_name.split(" ");
      setUserData({
        ...userData,
        name: temp[0],
        lastName: temp[1],
      });
    }
    // eslint-disable-next-line
  }, [auth.user]);

  React.useEffect(() => {
    const getEvents = async () => {
      let data = await listEvents(auth.user.token);
      console.log(data);
      setNextEvents(data);
      setLoading(false);
    };
    getEvents();
  }, [auth]);

  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    if (idsChecked.length === 0) {
      setOpen(false);
      return handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "No tiene eventos seleccionados",
        },
      });
    }

    setLoading(true);
    idsChecked.forEach(
      async (element) => await deleteEvent(element, auth.user.token)
    );
    setOpen(false);
    setEventDeleted(eventDeleted + 1);
    handleSnack({
      type: SNACKTYPES.success,
      payload: {
        message: "Eventos borrados con éxito",
      },
    });
  };

  const handleCheckBox = (e) => {
    let array = [...idsChecked];
    if (e.target.checked) {
      setIdsChecked([...idsChecked, e.target.id]);
    } else {
      for (let i = 0; i < array.length; i++) {
        if (array[i] === e.target.id) {
          array.splice(array.indexOf(e.target.id), 1);
        }
      }
      setIdsChecked(array);
    }
  };

  const handleShare = () => {
    alert("compartir evento");
  };

  return (
    <Layout>
      <div className="p-3">
        <HeaderMiPerfil datos={userData} />
        <TarjetaPerfil datos={userData} />
        <div
          className="d-flex justify-content-between row"
          style={{ position: "relative" }}
        >
          <p className="prox-eventos col-6">
            <img
              src={require("../../assets/images/prox.png")}
              alt="imagen prox eventos"
            />
            Próximos Eventos
          </p>
          <div className="seccion-agregar-evento col-3">
            <p className="agregar-evento">Agregar Evento</p>
            <img
              type="button"
              src={require("../../assets/images/bot-mas.png")}
              alt="boton mas"
              onClick={() => navigate("/agregar-evento")}
            />
          </div>
          <div className="col-3 d-flex align-items-center justify-content-around">
            <div>Editar Eventos</div>
            <div
              className="edit-mass-btn"
              type="button"
              onClick={() => setOptionButton(!optionButton)}
            >
              <img
                alt="imagen boton +"
                src={require("../../assets/images/ico-editar.png")}
              />
            </div>
            <div ref={optionMenu} className="options-menu-events">
              <div className="option-item" onClick={() => setOpen(true)}>
                Eliminar
              </div>
              <div className="option-item" onClick={handleShare}>
                Compartir
              </div>
            </div>
          </div>
        </div>
        <Loader visible={loading} />
        {nextEvents.length > 0 && !loading ? (
          nextEvents.map((e, idx) => (
            <TarjetaProximoEvento
              key={idx}
              datos={e}
              handleCheckBox={handleCheckBox}
            />
          ))
        ) : (
          <div
            className="no-events"
            style={{ visibility: loading ? "hidden" : "visible" }}
          >
            No tienes próximos eventos
          </div>
        )}
        <AlertDialog
          open={open}
          title="¿Desea eliminar los eventos seleccionados?"
          body={""}
          handleClose={handleClose}
          handleConfirm={handleDelete}
          bgColor={"red"}
        />
      </div>
    </Layout>
  );
};

export default MiPerfil;
