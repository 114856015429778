import React from "react";

const HeaderAgregarDeseo = () => {
  return (
    <div className="row align-items-center my-3 ms-2 header">
      <div className="col-3 d-flex align-items-center">
        {/* <img className="mb-3" src={require('../../assets/images/ico-estrella.png')} alt="foto estrella"/> */}
        <h4>Agregar Deseo</h4>
      </div>
      <div className="col-3">
        <button type="button" className="preferencias-reg ms-3 mb-2">
          <img
            src={require("../../assets/images/ico-reg.jpg")}
            alt="foto mensajes"
            className="img-mail"
          />
          ¡Con Regalo!
        </button>
      </div>
      <div className="col-3"></div>
      <div className="col-3 d-flex align-items-center justify-content-around">
        <div>Actualizar</div>
        <div type="button">
          <img
            src={require("../../assets/images/ico-actualizar.png")}
            alt="imagen boton +"
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderAgregarDeseo;
