import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const DateInput = ({ label, credentials, setCredentials }) => {
  const handleDateChange = (d) => {
    setCredentials({ ...credentials, date: d });
  };

  return (
    <div className="mb-3">
      <label htmlFor="password" className="form-label">
        {label}
      </label>
      <DatePicker
        selected={credentials.date}
        onChange={(date) => handleDateChange(date)}
        closeOnScroll={(e) => e.target === document}
        minDate={new Date()}
      />
    </div>
  );
};
