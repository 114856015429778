import React from "react";
import "./styles.css";

export const TarjetaMensaje = ({ datos }) => {
  return (
    <div className="tarjeta-prox-evento row">
      <div className="col-10 d-flex align-items-center">
        <input className="form-check-input baj" type="checkbox" />
        <p className="message-title">{datos.title}</p>
        <p className="p-prox-evento"></p>
      </div>
      <div className="col-2">
        <img
          src={require("../../assets/images/regalo-tarjeta2.png")}
          alt="imagen de regalo"
        />
      </div>
    </div>
  );
};
