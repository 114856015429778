import React from "react";
import "./styles.css";
import google from "../../assets/images/Logo-Google-Play.png";
import google_dark from "../../assets/images/Logo-Google-Play-negativo.png";
import apple from "../../assets/images/Logo-App-Store.png";
import apple_dark from "../../assets/images/Logo-App-Store-negativo.png";
import logo_giftoky from "../../assets/images/giftoky-blanco.png";
import facebook from "../../assets/images/ico-face2.png";
import instagram from "../../assets/images/ico-ins.png";
import x from "../../assets/images/ico-twitter23.png";

const ShareWishFooter = () => {
  return (
    <>
      <div className="share-footer-box">
        <div className="links-stores">
          <img src={google_dark} alt="google play" className="store-img" />
          <img src={apple_dark} alt="app store" className="store-img" />
        </div>
      </div>
      <div className="share-wish-footer-box">
        <FirstShareWishFooterSection />
      </div>
      <div className="share-wish-footer-box2">
        <SecondShareWishFooterSection />
      </div>
      <div className="share-wish-footer-box">
        <FinalShareWishFooterSection />
      </div>
    </>
  );
};

const FirstShareWishFooterSection = () => {
  return (
    <div className="first-footer-section">
      <div className="join-giftoky">
        <img
          src={logo_giftoky}
          alt="logo giftoky"
          className="logo-giftoky-footer"
        />
        <div className="space"></div>
        <div className="bold-text">
          Súmate a Giftoky gratis y descubre la nueva forma de regalar.
        </div>
        <div className="space"></div>
        <div className="normal-text">
          Descarga la app, crea tu cuenta y confirma el Email de tu amigo@ para
          ser su Referido y consigue tu regalo.
        </div>
        <div className="space"></div>
        <DownloadAppContainer />
      </div>
    </div>
  );
};

const SecondShareWishFooterSection = () => {
  return (
    <div className="second-footer-section">
      <div className="join-giftoky2">
        <div className="space2"></div>
        <div className="bold-text2">
          Tus ideas de compra en un solo lugar y gratis.
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
          Guarda fácilmente durante el año todos tus deseos de compras de
          cualquier tienda para no olvidarlos y compartir los que quieras con
          tus amig@s.
        </div>
        <div className="space2"></div>
        <div className="bold-text2">
          Tu también puedes recibir regalos perfectos.
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
          Invita a tus amig@s para que sepan siempre que regalarte. Créalos como
          contactos en la lista «Mis amig@s» y así podrán enterarse de tus
          deseos públicos recibiendo por fin los regalos que quieres ahorrándote
          la compra. Mientras más contactos tengas, más oportunidades tienes de
          conseguir los mejores regalos.
        </div>
        <div className="space2"></div>
        <div className="bold-text2">
          Guarda tus fechas y no pierdas ningún compromiso.
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
          Al registrar tus eventos importantes no se te pasará ninguno. También
          te recordamos festividades para que planifiques tus compras de regalo
          con anticipación aprovechando las mejores oportunidades.
        </div>
        <div className="space2"></div>
        <div className="bold-text2">
          Ahorra tiempo y transfórmate en un maestro de los regalos.
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
          Explora los deseos de tus amig@s y entérate que quieren recibir para
          entregar siempre un regalo que les importe. Selecciona el mejor para
          hacer una reserva anónima y exclusiva, evitando que tu obsequio se
          duplique y así sea perfecto.
        </div>
        <div className="space2"></div>
        <div className="bold-text2">Consumo con sentido.</div>
        <div className="space2"></div>
        <div className="normal-text2">
          Seamos responsables con el dinero y compremos regalos que realmente se
          utilicen. Tengamos conciencia con el medio ambiente y no transformemos
          un mal obsequio en basura.
        </div>
        <div className="space2"></div>
        <DownloadAppContainer darkMode={false} />
      </div>
    </div>
  );
};

const FinalShareWishFooterSection = () => {
  return (
    <div className="first-footer-section">
      <div className="space2"></div>
      <div className="join-giftoky">
        <div className="final-footer-links">
          <a
            href="https://giftoky.com/politicas-de-privacidad/"
            target="_blank"
            rel="noreferrer"
            className="normal-text"
          >
            Políticas de Privacidad
          </a>
          <a
            href="https://giftoky.com/terminos-y-condiciones/"
            target="_blank"
            rel="noreferrer"
            className="normal-text"
          >
            Términos y Condiciones
          </a>
        </div>
        <div className="space"></div>
        <a href="https://www.giftoky.com/" className="bold-text2">
          www.giftoky.com
        </a>
        {/* <div className="space"></div> */}
        <div className="normal-text">
          © 2023. GIFTOKY Co. Derechos reservados.
        </div>
        <div className="space2"></div>
        <FooterRRSSContainer />
      </div>
    </div>
  );
};

const DownloadAppContainer = ({ darkMode = true }) => {
  return (
    <>
      <div className="bold-text" style={{ display: "block" }}>
        DESCARGA LA APP GRATIS AHORA
      </div>
      <div className="links-stores">
        {/* <a href="#"> */}
        <img
          src={darkMode ? google_dark : google}
          alt="google play"
          className="store-img"
        />
        {/* </a> */}
        {/* <a href="#"> */}
        <img
          src={darkMode ? apple_dark : apple}
          alt="app store"
          className="store-img"
        />
        {/* </a> */}
      </div>
    </>
  );
};

const FooterRRSSContainer = () => {
  return (
    <>
      <div className="rrss-footer">
        <a
          href="https://www.facebook.com/giftokyapp"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/giftokyapp/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="isntagram" />
        </a>
        <a
          href="https://twitter.com/i/flow/login?redirect_after_login=%2Fgiftokyapp"
          target="_blank"
          rel="noreferrer"
        >
          <img src={x} alt="x" />
        </a>
      </div>
      <div className="key-app-words">
        <div className="normal-text">@GIFTOKYAPP</div>
        <div className="normal-text">#MEJORESREGALOS</div>
      </div>
    </>
  );
};

export default ShareWishFooter;
