import React from "react";
import Layout from "../../components/Layout";
import './styles.css'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="p-3">
        <h2>Políticas de privacidad</h2>
        <h3>1. General</h3>
        <p className="p-policy">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          sagittis tristique feugiat. Praesent sed mi posuere, luctus odio a,
          pharetra dui. Sed tristique feugiat nisi, a ultricies leo sagittis a.
          Phasellus eget consectetur ex. Nullam rhoncus, massa et dapibus gravida,
          ipsum massa sagittis ligula, in pellentesque est sem id ante. Aenean
          tristique porttitor ipsum. Nunc consequat diam nec ex mattis maximus.
          Sed commodo leo a euismod suscipit. Pellentesque et justo consequat,
          suscipit erat eu, elementum urna. Morbi iaculis mollis risus, ac luctus
          urna porttitor in. Maecenas pulvinar felis non lectus interdum accumsan.
          Maecenas ex sem, accumsan ut gravida dapibus, rhoncus ut leo. Nunc eget
          elementum ipsum.
        </p>
        <h3>2. Información que recolectamos</h3>
        <p className="p-policy">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          sagittis tristique feugiat. Praesent sed mi posuere, luctus odio a,
          pharetra dui. Sed tristique feugiat nisi, a ultricies leo sagittis a.
          Phasellus eget consectetur ex. Nullam rhoncus, massa et dapibus gravida,
          ipsum massa sagittis ligula, in pellentesque est sem id ante. Aenean
          tristique porttitor ipsum. Nunc consequat diam nec ex mattis maximus.
          Sed commodo leo a euismod suscipit. Pellentesque et justo consequat,
          suscipit erat eu, elementum urna. Morbi iaculis mollis risus, ac luctus
          urna porttitor in. Maecenas pulvinar felis non lectus interdum accumsan.
          Maecenas ex sem, accumsan ut gravida dapibus, rhoncus ut leo. Nunc eget
          elementum ipsum.
        </p>
        <h3>3. Cookies</h3>
        <p className="p-policy">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          sagittis tristique feugiat. Praesent sed mi posuere, luctus odio a,
          pharetra dui. Sed tristique feugiat nisi, a ultricies leo sagittis a.
          Phasellus eget consectetur ex. Nullam rhoncus, massa et dapibus gravida,
          ipsum massa sagittis ligula, in pellentesque est sem id ante. Aenean
          tristique porttitor ipsum. Nunc consequat diam nec ex mattis maximus.
          Sed commodo leo a euismod suscipit. Pellentesque et justo consequat,
          suscipit erat eu, elementum urna. Morbi iaculis mollis risus, ac luctus
          urna porttitor in. Maecenas pulvinar felis non lectus interdum accumsan.
          Maecenas ex sem, accumsan ut gravida dapibus, rhoncus ut leo. Nunc eget
          elementum ipsum.
        </p>
        <h3>4. Información obtenida por terceros</h3>
        <p className="p-policy">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          sagittis tristique feugiat. Praesent sed mi posuere, luctus odio a,
          pharetra dui. Sed tristique feugiat nisi, a ultricies leo sagittis a.
          Phasellus eget consectetur ex. Nullam rhoncus, massa et dapibus gravida,
          ipsum massa sagittis ligula, in pellentesque est sem id ante. Aenean
          tristique porttitor ipsum. Nunc consequat diam nec ex mattis maximus.
          Sed commodo leo a euismod suscipit. Pellentesque et justo consequat,
          suscipit erat eu, elementum urna. Morbi iaculis mollis risus, ac luctus
          urna porttitor in. Maecenas pulvinar felis non lectus interdum accumsan.
          Maecenas ex sem, accumsan ut gravida dapibus, rhoncus ut leo. Nunc eget
          elementum ipsum.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
