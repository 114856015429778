import React from "react";

export const HeaderMensajes = ({ datos }) => {
  return (
    <div className="row align-items-center my-3 ms-3 header">
      <div className="col-6 d-flex align-items-center">
        <h1 className="titulo-mi-perfil">
          Mensajes {datos.name} {datos.lastName}
        </h1>
      </div>
      <div className="col-3"></div>
      <div className="col-3 d-flex align-items-center justify-content-center flex-end">
        <div style={{ marginRight: "1rem" }}>Acciones</div>
        <div type="button" onClick={() => alert("view agregar deseo")}>
          <img
            src={require("../../assets/images/ico-editar.png")}
            alt="imagen boton +"
          />
        </div>
      </div>
    </div>
  );
};
