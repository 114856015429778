import React from "react";
import { useAuth } from "../../contexts/auth";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import {
  cancelFollowerIRequest,
  confirmFollowerRequest,
} from "../../services/tipddy";
import AlertDialog from "../AlertDialog";
import "./styles.css";

export const TarjetaAmigoRecibida = ({
  datos,
  refreshPage,
  setRefreshPage,
}) => {
  const [open, setOpen] = React.useState(false);
  const auth = useAuth();
  const [, handleSnack] = useSnack();

  const handleDelete = (id) => {
    cancelFollowerIRequest(id, auth.user.token)
      .then((res) => {
        console.log(res);
        handleSnack({
          type: SNACKTYPES.success,
          payload: {
            message: "Solicitud enviada, eliminada con éxito",
          },
        });
        setRefreshPage(refreshPage + 1);
      })
      .catch((e) => {
        console.error(e);
        handleSnack({
          type: SNACKTYPES.error,
          payload: {
            message:
              "Error al eliminar solicitud enviada, favor contactar con soporte",
          },
        });
      });
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleAcceptRequest = (id) => {
    confirmFollowerRequest(id, auth.user.token)
      .then((res) => {
        console.log(res);
        handleSnack({
          type: SNACKTYPES.success,
          payload: {
            message: "Solicitud aceptada con éxito",
          },
        });
        setRefreshPage(refreshPage + 1);
      })
      .catch((e) => {
        console.error(e);
        handleSnack({
          type: SNACKTYPES.error,
          payload: {
            message:
              "Error al aceptar solicitud de amistad,favor contactar con soporte",
          },
        });
      });
  };

  return (
    <>
      <div className="tarjeta-prox-evento row">
        <div className="col-10 d-flex align-items-center">
          <input className="form-check-input baj" type="checkbox" />
          <p className="message-title">
            Solicitud de amistad de {datos.display_name}
          </p>
          <p className="p-prox-evento"></p>
        </div>
        <div className="col-2 d-flex align-items-center justify-content-around">
          <img
            src={require("../../assets/images/ico-tick.png")}
            alt="imagen de ticket"
            className="ico-trash"
            onClick={() => handleAcceptRequest(datos.ID)}
          />
          <img
            alt="icono basurero"
            src={require("../../assets/images/ico-basurero.png")}
            className="ico-trash"
            onClick={() => handleOpen}
          />
        </div>
      </div>
      <AlertDialog
        open={open}
        title="¿Desea eliminar la/s solicitud/es de amistad seleccionada/as?"
        body={""}
        handleClose={handleClose}
        handleConfirm={() => handleDelete(datos.ID)}
        bgColor={"red"}
      />
    </>
  );
};
