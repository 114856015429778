import React from "react";

export const HeaderAgregarEvento = () => {
  return (
    <div className="row align-items-center my-3 ms-2 header">
      <div className="col-3 d-flex align-items-center">
        <h4>Agregar Evento</h4>
      </div>
      <div className="col-3"></div>
      <div className="col-3"></div>
      <div className="col-3 d-flex align-items-center justify-content-around"></div>
    </div>
  );
};
