import React from "react";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import info_icon from "./assets/info-circle.svg";

export const Snackbar = ({ message, severity, visible }) => {
  const visibility = visible ? 20 : -320;
  const [, handleSnack] = useSnack();

  if (visible) {
    setTimeout(() => {
      handleSnack({
        type: SNACKTYPES.close,
      });
    }, 3000);
  }

  const styles = {
    fixed: {
      position: "fixed",
      bottom: 20,
      left: visibility,
      backgroundColor: severity,
      height: 50,
      minWidth: 290,
      borderRadius: 5,
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
      transition: "0.4s",
      zIndex: 1100,
    },
    container: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%",
      padding: "0 1rem",
    },
    text: {
      color: "#fff",
      fontSize: 15,
      margin: 0,
    },
    icon: {
      margin: "0 1rem",
    },
  };

  return (
    <div style={styles.fixed}>
      <div style={styles.container}>
        <img alt="..." style={styles.icon} src={info_icon} />
        <p style={styles.text}>{message}</p>
      </div>
    </div>
  );
};
