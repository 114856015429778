import React from "react";
import { COLORS } from "../../utils/theme";
import "./styles.css";

export const OnBoarding = () => {
  const [page, setPage] = React.useState(0);
  const [isCompleted, setCompleted] = React.useState(true);

  const array = [
    {
      title:
        "Bienvenid@ a Giftoky!, donde te ayudamos a conseguir el regalo que realmente quieres.",
      content:
        "Felicitaciones! lo hiciste genial y gracias por elegirnos! Desde ahora puedes ahorrar compras compartiendo facilmente tus ideas! Basta de regalos sin sentido, duplicados o tener que usar el ticket de cambio",
    },
    {
      title: "Anota tus deseos durante el año y compártelos",
      content: `Al entrar sólo recuerda guardar siempre en "Mis Deseos" todo lo que quieres comprar para no olvidar nada. Puedes compartir tus ideas publicándolas en nuestra comunidad o bien enviándolas a tus amigo@s como sugerencias para que sepan que regalarte`,
    },
    {
      title: "Tus amig@s reservan un deseo para el regalo perfecto.",
      content:
        "Tus contactos siguen tu perfil, seleccionan el deseo y hacen una reserva anónima para entregarte el mejor regalo sin perder tiempo. Giftoky ! también te ayuda a hacer regalos, recordándote los eventos para que seas el primero en reservar ideas de tus amig@s.",
    },
    {
      title: "Explora y descubre como mejoran tus regalos",
      content:
        "Después que pruebes este demo crea tu cuenta gratis y si un amig@ te invitó recuerda ser su Referido ingresando su Email. Luego desbloquea funciones y empieza a disfrutar todo Giftoky! Si tienes alguna duda, recuerda que siempre puedes encontrar ayuda en el menú de perfil o escibirnos.",
    },
  ];

  React.useEffect(() => {
    let newUser = localStorage.getItem("@newUser");
    if (newUser === "true") {
      setCompleted(false);
    }
  }, []);

  const handleContinue = () => {
    if (page < 3) {
      setPage(page + 1);
      return;
    }
    localStorage.setItem("@newUser", false);
    setCompleted(true);
  };

  //considerar poner el texto en posicion absoluta para no tener opacidad
  return (
    <>
      {!isCompleted && (
        <div className="carrusel-background">
          {/* en el caso de no gustar el efecto transparente, generar un bloque father con posicion relative y el children en absolute
            para evitar efecto transparente */}
          <div className="box-carrusel text-center p-3">
            <img
              className="logo-onboarding"
              src={require("../../assets/images/giftoky-onboarding.png")}
              alt="logo giftoky"
            />
            <h1 className="titulo-onboarding">{array[page].title}</h1>
            <p className="texto-onboarding">{array[page].content}</p>
            <button
              type="button"
              className="btn-giftoky"
              onClick={handleContinue}
            >
              Continuar
            </button>
            <div className="row paginador-container">
              <div
                className="col-3 marcador-pagina"
                onClick={() => setPage(0)}
                style={{
                  backgroundColor: page === 0 ? COLORS.YELLOW : COLORS.PURPLE,
                }}
              ></div>
              <div
                className="col-3 marcador-pagina"
                onClick={() => setPage(1)}
                style={{
                  backgroundColor: page === 1 ? COLORS.YELLOW : COLORS.PURPLE,
                }}
              ></div>
              <div
                className="col-3 marcador-pagina"
                onClick={() => setPage(2)}
                style={{
                  backgroundColor: page === 2 ? COLORS.YELLOW : COLORS.PURPLE,
                }}
              ></div>
              <div
                className="col-3 marcador-pagina"
                onClick={() => setPage(3)}
                style={{
                  backgroundColor: page === 3 ? COLORS.YELLOW : COLORS.PURPLE,
                }}
              ></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
