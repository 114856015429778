export const DEFAULT_REQUEST_TABS = [
  {
    name: "Solicitudes enviadas",
  },
  {
    name: "Solicitudes recibidas",
  },
];

export const DEFAULT_FOLLOWERS_TABS = [
  {
    name: "Seguidos",
  },
  {
    name: "Seguidores",
  },
];
