import React, { useRef } from "react";
import "./styles.css";
import calendario from "../../assets/images/calendario.jpg";

const Calendario = ({ toggleCalendar }) => {
  const calendar = useRef(null);
  const recomendationsBox = useRef(null);
  const recomendationList = useRef(null);

  //considerar un componente distinto para los top regalo que traiga
  //los datos de este del administrador, asi se administra los que aparecen en la lista

  React.useEffect(() => {
    let calendario = document.getElementById("calendario")
    if (!toggleCalendar) {
      calendar.current.style.transform = `translateY(-${calendario.offsetHeight}px)`;
      recomendationsBox.current.style.transform = `translateY(-${calendario.offsetHeight}px)`;
      // recomendationsBox.current.style.height = "calc(100vh - 64px)";
      recomendationsBox.current.style.height = "100%";
    } else {
      calendar.current.style.transform = `translateY(0)`;
      recomendationsBox.current.style.transform = `translateY(0)`;
      // recomendationsBox.current.style.height = "calc(100vh - 369px)";
      recomendationsBox.current.style.height = "100%";
    }
    console.log("calendar width: ", calendario.offsetHeight)
  }, [toggleCalendar]);

  return (
    <>
      <div ref={calendar} id="calendario" className="calendario">
        <img
          className="foto-calendario"
          src={calendario}
          alt="foto calendario"
        />
      </div>
      <div ref={recomendationsBox} className="top text-center container px-0 recomendation-container">
        <h4 className="py-3">Top Regalos Giftoky</h4>
        <div
          ref={recomendationList}
          className="d-flex row recomendaciones ps-1"
        >
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-1.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-2.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-3.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-4.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-5.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-6.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-1.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-2.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-3.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-4.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-5.png")}
              alt="foto producto"
            />
          </div>
          <div className="bloque-recomendacion">
            <img
              className="img-recomendacion"
              src={require("../../assets/images/p-6.png")}
              alt="foto producto"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendario;
