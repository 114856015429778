import React from "react";
import { MagnifyingGlass } from "react-loader-spinner";
import { COLORS } from "../../utils/theme";

export const Loader = ({ visible }) => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-3">
      <MagnifyingGlass
        visible={visible}
        height="80"
        width="80"
        ariaLabel="MagnifyingGlass-loading"
        wrapperStyle={{}}
        wrapperClass="MagnifyingGlass-wrapper"
        glassColor={COLORS.YELLOW}
        color={COLORS.PURPLE}
      />
    </div>
  );
};
