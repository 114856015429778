import React from "react";

const Help = () => {
  return (
    <div className="p-3">
      <h2>Queremos Ayudarte</h2>
      <h3>¿Qué es Giftoky?</h3>
      <iframe
        width="100%"
        height="720"
        src="https://www.youtube.com/embed/u-5-mo84O6c"
        title="Presentación GiftMe"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <h4 className="p-policy">
        Bienvenid@ a Giftoky donde te ayudamos a conseguir el regalo que
        realmente quieres.
      </h4>
      <p className="p-policy">
        Debemos volver a dar valor al regalo, porque nos ahce feliz recibir lo
        que realmente queremos o necesitamos. Además nos ahorramos la compra y
        nuestr@s amig@s dejan de perder tiempo, entregando siempre el regalo
        perfecto. <br /> Con Giftoky evitas la duplicidad de regalos y los
        molestos ticket de cambio, mientras tus amig@s dejan de entregar cosas
        que pueden resultar inútiles. Ahora tienen múltiples sugerencias para
        planificar sus compras con anticipación, buscando las mejores
        oportunidades y acertando siempre con su obsequio de cumpleaños,
        navidad, matrimonio, san valentín, día de la madre, del padre, del niño,
        baby shower, bautizo, aniversario, amigo secreto, bienvenidas,
        despedidas, visitas, celebraciones, premios o simplemente el gusto de
        entregar cariño a los que queremos.
      </p>
      <h3>Como funciona</h3>
      <p className="p-policy">
        Primero tod@s anotamos fácil y frecuentemente lo que queremos comprar en
        la lista "Mis Deseos", agregando detalles para no olvidar nada,
        organizando todo en un solo lugar. <br /> Luego vamos agregando nuestros
        conocidos en "Mis Amig@s" para ir compartiendo con quien queramos las
        mejores sugerencias y asi recibimos los regalos perfectos. <br /> En "Mi
        Perfil" agrego mi información para ayudar a que mis amig@s acierten con
        los regalos y puedo ver el calendario próximas fechas para preparar las
        compras con anticipación y así no perder ningún evento.
        <br /> Primero descargas un Demo para probar Giftoky y puedas
        familiarizarte con él. <br /> Luego creas tu cuenta, guardas toda tu
        información y accedes a las funciones totalmente gratis! ;)
      </p>
      <h3>FAQs</h3>
      <p className="p-policy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
        sagittis tristique feugiat. Praesent sed mi posuere, luctus odio a,
        pharetra dui. Sed tristique feugiat nisi, a ultricies leo sagittis a.
        Phasellus eget consectetur ex. Nullam rhoncus, massa et dapibus gravida,
        ipsum massa sagittis ligula, in pellentesque est sem id ante. Aenean
        tristique porttitor ipsum. Nunc consequat diam nec ex mattis maximus.
        Sed commodo leo a euismod suscipit. Pellentesque et justo consequat,
        suscipit erat eu, elementum urna. Morbi iaculis mollis risus, ac luctus
        urna porttitor in. Maecenas pulvinar felis non lectus interdum accumsan.
        Maecenas ex sem, accumsan ut gravida dapibus, rhoncus ut leo. Nunc eget
        elementum ipsum.
      </p>
      <h3>Consejos</h3>
      <p className="p-policy">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
        sagittis tristique feugiat. Praesent sed mi posuere, luctus odio a,
        pharetra dui. Sed tristique feugiat nisi, a ultricies leo sagittis a.
        Phasellus eget consectetur ex. Nullam rhoncus, massa et dapibus gravida,
        ipsum massa sagittis ligula, in pellentesque est sem id ante. Aenean
        tristique porttitor ipsum. Nunc consequat diam nec ex mattis maximus.
        Sed commodo leo a euismod suscipit. Pellentesque et justo consequat,
        suscipit erat eu, elementum urna. Morbi iaculis mollis risus, ac luctus
        urna porttitor in. Maecenas pulvinar felis non lectus interdum accumsan.
        Maecenas ex sem, accumsan ut gravida dapibus, rhoncus ut leo. Nunc eget
        elementum ipsum.
      </p>
      <h3>
        Si no pudiste resolver tu duda, porfavor escríbenos y cuenta con
        nosotros hello@giftoky.com
      </h3>
    </div>
  );
};

export default Help;
