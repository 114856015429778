import React from "react";
import "./styles.css";
import logo from "../../assets/images/logo-giftoky.png";
import estrella from "../../assets/images/ico-estrella.png";
import amigos from "../../assets/images/ico-amigos.png";
import perfil from "../../assets/images/ico-perfil.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import AlertDialog from "../AlertDialog";
import {
  listIRequests,
  listRequests,
  verifyReservedGift,
} from "../../services/tipddy";

const NavBar = ({ toggleCalendar, setToggleCalendar }) => {
  const [currentNav, setCurrentNav] = React.useState();
  const [followRequest, setFollowRequest] = React.useState([]);
  const [requestsSent, setRequestsSent] = React.useState([]);
  const [reservedGift, setReservedGift] = React.useState([]);
  const [isEmpty, setEmpty] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const handleCalendar = () => {
    setToggleCalendar(!toggleCalendar);
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    auth.Logout();
  };

  React.useEffect(() => {
    setCurrentNav(window.location.pathname);
    let deseos = document.querySelector("#mis-deseos");
    let friends = document.querySelector("#mis-amigos");
    let profile = document.querySelector("#mi-perfil");
    if (currentNav === "/") {
      deseos.style.backgroundColor = "#ffd136";
    }
    if (currentNav === "/mis-contactos") {
      friends.style.backgroundColor = "#ffd136";
    }
    if (currentNav === "/mi-perfil") {
      profile.style.backgroundColor = "#ffd136";
    }
  }, [currentNav]);

  React.useEffect(() => {
    if (auth.user) {
      listRequests(auth.user.token)
        .then((res) => {
          // console.log("listRequest: ", res);
          setFollowRequest(res.result);
        })
        .catch((e) => {
          console.error(e);
        });
      listIRequests(auth.user.token)
        .then((res) => {
          // console.log("listIRequest: ", res);
          setRequestsSent(res.result);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [auth.user]);

  React.useEffect(() => {
    if (auth.user) {
      verifyReservedGift(auth.user.ID, auth.user.token).then((res) => {
        console.log("gift reserved:", res.result);
        setReservedGift(res.result);
      });
    }
  }, [auth.user]);

  React.useEffect(() => {
    setEmpty(true);
    if (followRequest.length > 0 || requestsSent.length > 0) {
      setEmpty(false);
    }
    //eslint-disable-next-line
  }, [followRequest, requestsSent]);

  return (
    <div className="d-flex row text-center nav-bar">
      <div
        type="button"
        className="ancho-nav-bar nav-item py-3"
        onClick={() => navigate("/")}
      >
        <img src={logo} alt="logo giftoky" />
      </div>
      <div
        type="button"
        id="mis-deseos"
        className="ancho-nav-bar nav-item py-3"
        onClick={() => navigate("/")}
      >
        <div className="medal-container">
          <img src={estrella} alt="logo giftoky" />
          {reservedGift.length > 0 ? (
            <div className="medal text-center">{reservedGift.length}</div>
          ) : (
            <div style={{ visibility: "hidden" }}></div>
          )}
        </div>
        Mis Deseos
      </div>
      <div
        type="button"
        id="mis-amigos"
        className="ancho-nav-bar nav-item py-3"
        onClick={() => navigate("/mis-contactos")}
      >
        <div className="medal-container">
          <img src={amigos} alt="logo giftoky" />
          {!isEmpty ? (
            <div className="medal text-center">
              {followRequest.length + requestsSent.length}
            </div>
          ) : (
            <div style={{ visibility: "hidden" }}></div>
          )}
        </div>
        Mis Contactos
      </div>
      <div
        type="button"
        id="mi-perfil"
        className="ancho-nav-bar nav-item py-3"
        onClick={() => navigate("/mi-perfil")}
      >
        <img src={perfil} alt="logo giftoky" />
        Mi Perfil
      </div>
      <div
        type="button"
        className="ancho-nav-bar nav-item py-3"
        onClick={handleCalendar}
      >
        Calendario
      </div>
      <div
        type="button"
        className="ancho-nav-bar nav-item py-3"
        onClick={handleOpen}
      >
        Logout
      </div>
      <AlertDialog
        open={open}
        title="¿Desea cerrar sesión?"
        body={""}
        handleClose={handleClose}
        handleConfirm={handleLogout}
      />
    </div>
  );
};

export default NavBar;
