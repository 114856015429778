import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { Loader } from "../../components/Loader";
import { SearchResults } from "../../components/SearchComponent";
import TextInput from "../../components/TextInput";
import { useAuth } from "../../contexts/auth";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import { getUserByParam, sendFollowerRequest } from "../../services/tipddy";
import { DEFAULT_FRIEND_REQUEST } from "../../utils/state";
import "./styles.css";

export const AgregarAmigo = () => {
  const [credentials, setCredentials] = React.useState(DEFAULT_FRIEND_REQUEST);
  const [searchParam, setSearchParam] = React.useState("");
  const [giftokers, setGiftokers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedUserData, setSelectedUserData] = React.useState("");
  const [, handleSnack] = useSnack();
  const navigate = useNavigate();
  const auth = useAuth();

  React.useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      if (searchParam.length > 0) {
        let data = await getUserByParam(searchParam, auth.user.token);
        console.log(data);
        if (data.length > 0) {
          setGiftokers([...data]);
          setLoading(false);
        } else {
          setGiftokers([]);
          setLoading(false);
        }
      }
    }, 1000);
  }, [searchParam, auth.user.token]);

  const handleSendFriendRequest = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    console.log(credentials);
    if (isEmpty) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Debe elegir un usuario",
        },
      });
      return;
    }
    try {
      let data = await sendFollowerRequest(credentials, auth.user.token);
      console.log(data);
      handleSnack({
        type: SNACKTYPES.success,
        payload: {
          message: "Solicitud enviada con éxito",
        },
      });
      navigate("/mis-contactos");
    } catch (e) {
      console.error(e);
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Error al enviar la solicitud",
        },
      });
    }
  };

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  return (
    <Layout>
      <div className="p-3">
        <div className="row align-items-center my-3 header">
          <div className="col-12 d-flex align-items-center">
            <h4>Buscar Contacto</h4>
          </div>
        </div>
        <div className="position-relative">
          <TextInput
            name={"title"}
            onChange={(e) => handleSearch(e)}
            label={"Buscar Nombre Contacto"}
            type={"text"}
            placeHolder={searchParam}
          />
          <div>
            {searchParam.length >= 3 && (
              <div className="search-container">
                {!loading ? (
                  giftokers.map((element, idx) => (
                    <SearchResults
                      search={searchParam}
                      friend_name={element.display_name}
                      key={idx}
                      loading={loading}
                      credentials={credentials}
                      setCredentials={setCredentials}
                      setSearch={setSearchParam}
                      id={element.ID}
                      type="add_friend"
                      setSelectedUserData={setSelectedUserData}
                    />
                  ))
                ) : (
                  <Loader visible={loading} />
                )}
              </div>
            )}
          </div>
        </div>
        {selectedUserData.length > 0 && <h1>Usuario {selectedUserData}</h1>}
        <div
          type="button"
          className="send-friend-request"
          onClick={(e) => handleSendFriendRequest(e)}
        >
          Enviar Solicitud
        </div>
      </div>
    </Layout>
  );
};
