import React from "react";
import Calendario from "../Calendario";
import NavBar from "../NavBar";
import { OnBoarding } from "../OnBoarding";
import SideNavBar from "../SideNavBar";

const Layout = ({ children }) => {
  const [toggleCalendar, setToggleCalendar] = React.useState(true);

  return (
    <>
      <div className="container-fluid">
        <NavBar
          toggleCalendar={toggleCalendar}
          setToggleCalendar={setToggleCalendar}
        />
        <div className="row">
          <div className="ancho-side-bar px-0">
            <SideNavBar />
          </div>
          <div className="ancho-content px-0">
            <img
              src={require("../../assets/images/publicidad2.jpg")}
              alt="publicidad"
              className="img-publicidad"
              style={{height: `${window.screen.width*0.15}px`}}
            />
            {children}
          </div>
          <div className="ancho-calendario px-0">
            <Calendario toggleCalendar={toggleCalendar} />
          </div>
        </div>
      </div>
      <OnBoarding />
    </>
  );
};

export default Layout;
