import React from "react";
import ReactFacebookLogin from "react-facebook-login";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../services/tipddy";
import { DEFAULT_REGISTER } from "../../utils/state";
import TextInput from "../../components/TextInput";
import "./styles.css";
import { SNACKTYPES, useSnack } from "../../contexts/snack";

const Register = () => {
  const [credentials, setCredentials] = React.useState(DEFAULT_REGISTER);
  const [isValidPassword, setValidPassword] = React.useState(null);
  const [, handleSnack] = useSnack();
  const navigate = useNavigate();
  //cambiar alerts por snackbars

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    if (isEmpty) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Complete todos los campos",
        },
      });
      return;
    }
    if (!isValidPassword) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Contraseñas no coinciden",
        },
      });
      return;
    }
    console.log(credentials);
    try {
      let new_user = await registerUser({
        ...credentials,
        username: credentials.email,
      });
      if (new_user) {
        handleSnack({
          type: SNACKTYPES.success,
          payload: {
            message: "usuario creado",
          },
        });
      }
      localStorage.setItem("@newUser", true);
      navigate("/");
    } catch (e) {
      console.log(e);
    }
  };

  const handleGoogle = async (credentialResponse) => {
    await handleGoogleLogin(credentialResponse);
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      let googleLogin = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${credentialResponse.credential}`
      );
      let res = await googleLogin.json();
      return res;
      // navigate("/mi-perfil");
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirmPassword = ({ target: { value } }) => {
    if (value === credentials.password) {
      setValidPassword(true);
      return;
    }
    setValidPassword(false);
  };

  return (
    <div className="d-flex">
      <div className="img-login">
        <img
          src={require("../../assets/images/foto-login3.jpg")}
          alt="imagen login"
        />
      </div>
      <div className="form-container-login">
        <form className="form-login">
          <img
            type="button"
            className="logo-login"
            src={require("../../assets/images/logo-net.png")}
            alt="logo giftoky"
            onClick={() => navigate("/login")}
          />
          <TextInput
            name={"first_name"}
            onChange={handleChange}
            label={"Ingrese su nombre"}
            type={"text"}
            ancho={true}
          />
          <TextInput
            name={"last_name"}
            onChange={handleChange}
            label={"Ingrese su apellido"}
            type={"text"}
            ancho={true}
          />
          <TextInput
            name={"email"}
            onChange={handleChange}
            label={"Ingrese su correo"}
            type={"email"}
            ancho={true}
          />
          <TextInput
            name={"password"}
            onChange={handleChange}
            label={"Contraseña"}
            type={"password"}
            ancho={true}
          />
          <TextInput
            name={"confirm_password"}
            onChange={handleConfirmPassword}
            label={"Confirme contraseña"}
            type={"password"}
            ancho={true}
          />
          <button
            type="submit"
            className="btn-giftoky ancho-login"
            onClick={(e) => handleSubmit(e)}
          >
            Registrarse
          </button>
          <div className="links-login ancho-login alternate-login-button">
            <GoogleLogin
              size="large"
              onSuccess={handleGoogle}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            <div> o </div>
            <ReactFacebookLogin
              appId="843954636764302"
              fields="name,email,picture"
              cssClass="facebook-button"
              textButton="Continuar con Facebook"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
