import React from "react";

const TextArea = ({ name, onChange, label, placeHolder = "" }) => {
  return (
    <div className="mb-3">
      <label htmlFor="floatingTextarea" className="form-label">
        {label}
      </label>
      <textarea
        className="form-control"
        name={name}
        onChange={onChange}
        placeholder={placeHolder}
        value={placeHolder}
        style={{ height: "75px" }}
      ></textarea>
    </div>
  );
};

export default TextArea;
