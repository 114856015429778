import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const HeaderMisAmigos = ({ followRequest, requestsSent }) => {
  const [isEmpty, setEmpty] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    setEmpty(true);
    if (followRequest.length > 0 || requestsSent.length > 0) {
      setEmpty(false);
    }
    //eslint-disable-next-line
  }, [followRequest, requestsSent]);

  return (
    <>
      <div className="row align-items-center my-3 ms-2 header">
        <div className="col-3 d-flex align-items-center">
          {/* <img className="mb-3" src={require('../../assets/images/ico-amigos.png')} alt="foto perfil"/> */}
          <h4>Mis Contactos</h4>
        </div>
        <div className="col-3">
          <button
            className="mis-preferencias"
            onClick={() => navigate("/contact-messages")}
          >
            <div>Mis solicitudes</div>
            {!isEmpty ? (
              <div className="medal-btn text-center">
                {followRequest.length + requestsSent.length}
              </div>
            ) : (
              <div style={{ visibility: "hidden" }}></div>
            )}
          </button>
        </div>
        <div className="col-3"></div>
        <div className="col-3 d-flex align-items-center justify-content-around">
          <div>Agregar Contacto</div>
          <div type="button" onClick={() => navigate("/agregar-contacto")}>
            <img
              src={require("../../assets/images/bot-mas.png")}
              alt="imagen boton +"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMisAmigos;
