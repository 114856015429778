import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({
  open,
  title,
  body,
  handleClose,
  handleConfirm,
  bgColor = "gray",
}) {
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ backgroundColor: bgColor, color: "white" }}>
          {title}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: bgColor }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ color: "white" }}
          >
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: bgColor, color: "white" }}>
          <Button onClick={handleConfirm} sx={{ color: "white" }}>
            Confirmar
          </Button>
          <Button onClick={handleClose} sx={{ color: "white" }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
