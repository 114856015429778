import React from "react";
import Select from "react-select";
import HeaderMisAmigos from "../../components/HeaderMisAmigos";
import TarjetaMisAmigos from "../../components/TarjetaMisAmigos";
import {
  listFollowers,
  listIFollowed,
  listIRequests,
  listRequests,
} from "../../services/tipddy";
import "./styles.css";
import { useAuth } from "../../contexts/auth";
import Layout from "../../components/Layout";
import { DEFAULT_FOLLOWERS_TABS } from "../../utils";
import BasicTabs from "../../components/Tabs";
import { Loader } from "../../components/Loader";

const MisAmigos = () => {
  const [amigos, setAmigos] = React.useState([]);
  const [followers, setFollowers] = React.useState([]);
  const [followed, setFollowed] = React.useState([]);
  const [followRequest, setFollowRequest] = React.useState([]);
  const [requestsSent, setRequestsSent] = React.useState([]);
  const [viewSelected, setViewSelected] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const auth = useAuth();

  React.useEffect(() => {
    setLoader(true);
    if (auth.user) {
      listFollowers(auth.user.token).then((res) => {
        let data = res.result;
        let newArray = [];
        data.forEach((e, i) => {
          console.log(i, e);
          let temp = "";
          temp = e.display_name.split(" ");
          newArray.push({ ...e, name: temp[0], lastName: temp[1] });
        });
        setFollowers(newArray);
      });
      listIFollowed(auth.user.token).then((res) => {
        let data = res.result;
        let newArray2 = [];
        data.forEach((e) => {
          let temp = "";
          temp = e.display_name.split(" ");
          newArray2.push({ ...e, name: temp[0], lastName: temp[1] });
        });
        setFollowed(newArray2);
      });
      setLoader(false);
    }
    //eslint-disable-next-line
  }, [auth.user]);

  React.useEffect(() => {
    viewSelected === 0 ? setAmigos(followed) : setAmigos(followers);
    console.log(followers);
  }, [viewSelected, followed, followers]);

  React.useEffect(() => {
    if (auth.user) {
      listRequests(auth.user.token).then((res) => {
        setFollowRequest(res.result);
      });
      listIRequests(auth.user.token).then((res) => {
        // console.log("solicitudes enviadas: ", res.result);
        setRequestsSent(res.result);
      });
    }
  }, [auth.user]);

  const handleFilter = (e) => {
    if (e.value === "nameAZ") {
      const sortedName = [...amigos].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setAmigos(sortedName);
      return;
    }
    if (e.value === "nameZA") {
      const sortedName = [...amigos].sort((a, b) =>
        b.name.localeCompare(a.name)
      );
      setAmigos(sortedName);
      return;
    }
    if (e.value === "lastNameAZ") {
      const sortedName = [...amigos].sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );
      setAmigos(sortedName);
      return;
    }
    if (e.value === "lastNameZA") {
      const sortedName = [...amigos].sort((a, b) =>
        b.lastName.localeCompare(a.lastName)
      );
      setAmigos(sortedName);
      return;
    }
  };

  const options = [
    { value: "", label: "--Elija una Opción--" },
    { value: "nameAZ", label: "Nombre A-Z" },
    { value: "nameZA", label: "Nombre Z-A" },
    { value: "lastNameAZ", label: "Apellido A-Z" },
    { value: "lastNameZA", label: "Apellido Z-A" },
  ];

  React.useEffect(() => {
    let borderInput = document.querySelector(".css-1s2u09g-control");
    let placeholderInput = document.querySelector(".css-14el2xx-placeholder");
    borderInput.style.border = "none";
    borderInput.style.boxShadow = "none";
    placeholderInput.style.color = "#981E97";
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className="p-3">
        <HeaderMisAmigos
          followRequest={followRequest}
          requestsSent={requestsSent}
        />
        <BasicTabs
          tabs={DEFAULT_FOLLOWERS_TABS}
          viewselected={viewSelected}
          setViewSelected={setViewSelected}
        />
        <div style={{ marginBottom: "1rem" }}>Ordenar Por:</div>
        <Select
          onChange={handleFilter}
          options={options}
          placeholder="Elija una opción"
        />
        <div className="filete"></div>
        <Loader visible={loader} />
        {amigos.length > 0 ? (
          amigos.map((e, idx) => {
            return <TarjetaMisAmigos datos={e} key={idx} />;
          })
        ) : (
          <div className="d-flex text-center justify-content-center">
            No tienes amigos registrados
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MisAmigos;
