import React from "react";
import "./styles.css";
import HeaderAgregarDeseo from "../../components/HeaderAgregarDeseo";
import TextInput from "../../components/TextInput";
import { DEFAULT_WISH } from "../../utils/state";
import TextArea from "../../components/TextArea";
import { useNavigate } from "react-router-dom";
import InputImage from "../../components/InputImage";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import { getGiftByID, createGift, updateGift } from "../../services/tipddy";
import { useAuth } from "../../contexts/auth";
import Layout from "../../components/Layout";

const ContenidoAgregarDeseo = () => {
  const [credentials, setCredentials] = React.useState(DEFAULT_WISH);
  const [isPublic, setPublic] = React.useState(true);
  const [wishExist, setWishExist] = React.useState(false);
  const [, handleSnack] = useSnack();
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    let paramID = document.URL.split("?")[1];
    if (paramID !== undefined) {
      let wishData = async () => {
        let data = await getGiftByID(paramID, auth.user.token);
        console.log(data.images);
        setCredentials({
          title: data.title,
          image1: data.images.image1,
          image2: data.images.image2,
          description: data.detail,
          price: data.price,
          link: data.link,
          public: data.public,
          id: paramID,
        });
        if (data.public === "no") {
          setPublic(false);
        }
        setWishExist(true);
        return data;
      };
      wishData();
    }

    //eslint-disable-next-line
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
    console.log(credentials);
  };

  const handleCheckbox = (p) => {
    setPublic(p);
    setCredentials({ ...credentials, public: p ? "yes" : "no" });
  };

  const handleSaveWish = async (e) => {
    e.preventDefault();
    const isEmpty = Object.values(credentials).some((value) => value === "");
    console.log(credentials);
    if (isEmpty) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Complete todos los campos",
        },
      });
      return;
    }
    if (wishExist) {
      const data = await updateGift(credentials, auth.user.token);
      console.log("update: ", data);
      handleSnack({
        type: SNACKTYPES.success,
        payload: {
          message: "Deseo actualizado con éxito",
        },
      });
    } else {
      const data = await createGift(credentials, auth.user.token);
      console.log("register: ", data);
      handleSnack({
        type: SNACKTYPES.success,
        payload: {
          message: "Deseo creado con éxito",
        },
      });
    }

    navigate("/");
  };

  return (
    <Layout>
      <div className="p-3">
        <HeaderAgregarDeseo />
        <section className="agregar-fotos">
          <InputImage
            n={1}
            credentials={credentials}
            setCredentials={setCredentials}
            src={credentials.image1}
          />
          <InputImage
            n={2}
            credentials={credentials}
            setCredentials={setCredentials}
            src={
              credentials.image2 !== ""
                ? credentials.image2
                : require("../../assets/images/subir-imagen.png")
            }
          />
          <div className="col-lg-4"></div>
          {/* <InputImage
          n={3}
          credentials={credentials}
          setCredentials={setCredentials}
        /> */}
        </section>
        <section className="form-agregar-deseo">
          <TextInput
            name={"title"}
            onChange={handleChange}
            label={"Nombre"}
            type={"text"}
            placeHolder={credentials.title}
          />
          <TextInput
            name={"price"}
            onChange={handleChange}
            label={"Precio"}
            type={"number"}
            placeHolder={credentials.price}
          />
          <TextArea
            name={"description"}
            onChange={handleChange}
            label={"Detalles"}
            placeHolder={credentials.description}
          />
          <TextInput
            name={"link"}
            onChange={handleChange}
            label={"URL"}
            type={"url"}
            placeHolder={credentials.link}
          />
        </section>
        <section className="mover-deseo">
          <p className="font-medium">Mover deseo</p>
          <div className="checklist-agregar-deseo">
            <input
              className="form-check-input baj"
              type="checkbox"
              onClick={() => handleCheckbox(false)}
              checked={isPublic ? false : true}
            />
            <div className="col-11">
              <p className="text-checkbox">Comprado</p>
            </div>
          </div>
          <div className="checklist-agregar-deseo">
            <input
              className="form-check-input baj"
              type="checkbox"
              onClick={() => handleCheckbox(true)}
              checked={isPublic ? true : false}
            />
            <div className="col-11">
              <p className="text-checkbox">Público</p>
            </div>
          </div>
          <div
            type="button"
            className="save"
            onClick={(e) => handleSaveWish(e)}
          >
            Guardar deseo
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ContenidoAgregarDeseo;
