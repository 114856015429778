import React from "react";
import "./styles.css";

const SideNavBar = () => {
  return (
    <div className="side-nav-bar text-center flex-column d-flex py-3 px-0">
      <ul className="navbar-nav">
        <li className="side-nav-item">
          <a href="/invitar-referidos">Invitar y referidos</a>
        </li>
        <li className="side-nav-item">
          <a href="/ayuda">Ayuda</a>
        </li>
        <li className="side-nav-item">
          <a href="/">Contacto</a>
        </li>
        <li className="side-nav-item side-ad">
          <img
            className="ancho-publicidad"
            src={require("../../assets/images/publicidad.jpg")}
            alt="publicidad"
          />
        </li>
        <li className="side-nav-item">
          <a href="/politicas-de-privacidad">Políticas de privacidad</a>
        </li>
        <li className="side-nav-item mt-auto">
          <a
            href="https://web.farmaciasahumada.cl/fasaonline/fasa/documentos/miercoles-dermo-septiembre-7-14-21-y-28.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Términos y condiciones
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideNavBar;
