import React from "react";
import "./styles.css";

const InputImage = ({ n, src, credentials, setCredentials }) => {
  const [source, setSource] = React.useState(src);
  const [blob, setBlob] = React.useState(null);
  const ref = React.useRef(null);

  const readImage = (e) => {
    const [file] = e.target.files;
    if (file) {
      let something = URL.createObjectURL(file);
      setBlob(something);
      ref.current.classList.remove("bloque-foto");
      setCredentials({ ...credentials, [`image${n}`]: file });
    }
  };

  const handleRemoveImage = () => {
    setBlob(null);
    ref.current.classList.add("bloque-foto");
    setCredentials({ ...credentials, [`image${n}`]: null });
  };

  React.useEffect(() => {
    if (blob) {
      setSource(blob);
      return;
    }
    if (src) {
      ref.current.classList.remove("bloque-foto");
    }
    setSource(src);
  }, [src, blob]);

  return (
    <div className="bloque-subir-foto d-flex col-lg-4 align-items-center justify-content-center">
      <div
        id={`bloque-foto-${n}`}
        className="bloque-foto d-flex col-lg-4 align-items-center justify-content-center"
        ref={ref}
      >
        <label className="file-upload-animation" htmlFor={"file-upload-" + n}>
          <img
            id={"blah-" + n}
            src={
              source ? source : require("../../assets/images/subir-imagen.png")
            }
            alt="subir imagen"
            className="subir-imagen"
          />
        </label>
        <input
          className="input col-lg-4 file-upload-input"
          id={"file-upload-" + n}
          type="file"
          accept="image/*"
          onChange={readImage}
        />
      </div>
      <img
        src={require("../../assets/images/bot-mas.png")}
        className="close file-upload-animation"
        alt="boton eliminar foto subida"
        onClick={() => handleRemoveImage(n)}
      />
    </div>
  );
};

export default InputImage;
