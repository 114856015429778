import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const TarjetaProximoEvento = ({ datos, handleCheckBox }) => {
  const navigate = useNavigate();

  return (
    <div className="tarjeta-prox-evento row align-items-center event-item">
      <div className="col-9 d-flex">
        <input
          className="form-check-input baj"
          type="checkbox"
          value={datos.title}
          id={datos.ID}
          onChange={(e) => handleCheckBox(e)}
          style={{ margin: "1rem 1rem 0 0" }}
        />
        <div>
          <p className="nombre-prox-evento">{datos.title}</p>
          <p className="p-prox-evento">{datos.date}</p>
        </div>
      </div>
      <div className="col-2">
        {datos.gift_status === "given" && (
          <img
            src={require("../../assets/images/regalo-tarjeta2.png")}
            alt="imagen de regalo"
          />
        )}
      </div>
      <div className="col-1">
        <img
          type="button"
          src={require("../../assets/images/vista.png")}
          alt="imagen de ojo"
          onClick={() => navigate(`/agregar-evento?${datos.ID}`)}
        />
      </div>
    </div>
  );
};

export default TarjetaProximoEvento;
