import React, { useState } from "react";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [verify, setVerify] = useState(false);

  let actions = {
    Auth(payload) {
      localStorage.setItem("@userdata", JSON.stringify(payload));
      setUser(payload);
    },
    VerifyAuth() {
      let userData = JSON.parse(localStorage.getItem("@userdata"));
      if (userData) {
        setUser(userData);
      }
      setVerify(true);
    },
    Logout() {
      localStorage.removeItem("@userdata");
      setUser(null);
    },
  };

  let value = {
    user,
    setUser,
    verify,
    Auth: actions.Auth,
    VerifyAuth: actions.VerifyAuth,
    Logout: actions.Logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
