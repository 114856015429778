import React from "react";
import { useAuth } from "../../contexts/auth";
import { DEFAULT_LOGIN } from "../../utils/state";
import "./styles.css";
// import CheckboxInput from "../CheckboxInput";
import TextInput from "../../components/TextInput";
import { authenticate } from "../../services/tipddy";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import { useSnack, SNACKTYPES } from "../../contexts/snack";

const Login = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [, handleSnack] = useSnack();
  const [credentials, setCredentials] = React.useState(DEFAULT_LOGIN);

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmpty = Object.values(credentials).some((value) => value === "");

    if (isEmpty) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "LLene todos los campos",
        },
      });
      return;
    }
    try {
      let data = await authenticate({ ...credentials });
      console.log(data);
      if (data.response?.status === 403) {
        handleSnack({
          type: SNACKTYPES.error,
          payload: {
            message: "Usuario o contraseña incorrecta",
          },
        });
        throw new Error(data);
      }
      if (data.data?.token) {
        auth.Auth(data.data);
        navigate("/mi-perfil");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleGoogle = async (credentialResponse) => {
    await handleGoogleLogin(credentialResponse);
  };

  const handleGoogleLogin = async (credentialResponse) => {
    try {
      let googleLogin = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${credentialResponse.credential}`
      );
      let res = await googleLogin.json();
      return res;
      // navigate("/mi-perfil");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="d-flex" style={{ height: "100vh" }}>
      <div className="img-login">
        <img
          src={require("../../assets/images/foto-login3.jpg")}
          alt="imagen login"
          className="img-bg-login"
        />
      </div>
      <div className="form-container-login">
        <form className="form-login">
          <img
            type="button"
            className="logo-login"
            src={require("../../assets/images/logo-net.png")}
            alt="logo giftoky"
            onClick={() => navigate("/login")}
          />
          <TextInput
            name={"username"}
            onChange={handleChange}
            label={"Ingrese su usuario"}
            type={"text"}
            ancho={true}
          />
          <TextInput
            name={"password"}
            onChange={handleChange}
            label={"Ingrese su Contraseña"}
            type={"password"}
            ancho={true}
          />
          {/* <CheckboxInput label={"Recuerdame"} /> */}
          <button
            type="submit"
            className="btn-giftoky ancho-login"
            onClick={(e) => handleSubmit(e)}
            style={{ marginTop: "2rem" }}
          >
            Ingresar
          </button>
          <div className="links-login ancho-login ">
            <div
              type="button"
              className="forgot-password"
              onClick={() => navigate("/forgot")}
            >
              ¿Olvidaste tu contraseña?
            </div>
            <div
              type="button"
              className="register"
              onClick={() => navigate("/registrar")}
            >
              Registrate ahora
            </div>
          </div>
          <div className="links-login ancho-login alternate-login-button">
            <GoogleLogin
              size="large"
              onSuccess={handleGoogle}
              onError={() => {
                console.log("Login Failed");
              }}
            />
            <div> o </div>
            <FacebookLogin
              appId="843954636764302"
              fields="name,email,picture"
              cssClass="facebook-button"
              textButton="Continuar con Facebook"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
