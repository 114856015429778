import React from "react";
import "./styles.css";

const TextInput = ({
  name,
  onChange,
  label,
  type,
  ancho,
  placeHolder = "",
}) => {
  return (
    <>
      {ancho ? (
        <div className="mb-3 ancho-login" id="input-container">
          <label htmlFor="password" className="form-label">
            {label}
          </label>
          <input
            name={name}
            type={type}
            className="form-control input-box"
            onChange={onChange}
          />
        </div>
      ) : (
        <div className="mb-3" id="input-container">
          <label htmlFor="password" className="form-label">
            {label}
          </label>
          <input
            name={name}
            type={type}
            className="form-control input-box"
            onChange={onChange}
            value={placeHolder}
          />
        </div>
      )}
    </>
  );
};

export default TextInput;
