import React from "react";
import { useAuth } from "../../contexts/auth";
import { HeaderMensajes } from "../../components/HeaderMensajes";
import Layout from "../../components/Layout";
import { listIRequests, listRequests } from "../../services/tipddy";
import { TarjetaAmigoRecibida } from "../../components/TarjetaAmigoRecibida";
import { DEFAULT_REQUEST_TABS } from "../../utils";
import BasicTabs from "../../components/Tabs";
import { TarjetaAmigoEnviada } from "../../components/TarjetaAmigoEnviada";
// import { listRequests } from "../../services/tipddy";

export const MensajesAmigos = () => {
  const [userData, setUserData] = React.useState({});
  const [friendRequests, setFriendRequests] = React.useState([]);
  const [requestsSent, setRequestsSent] = React.useState([]);
  const [isEmpty, setEmpty] = React.useState(true);
  const [viewSelected, setViewSelected] = React.useState(0);
  const [refreshPage, setRefreshPage] = React.useState(0);
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.user) {
      listRequests(auth.user.token).then((res) => {
        // console.log("solicitud recibida: ", res.result);
        setFriendRequests(res.result);
      });

      listIRequests(auth.user.token).then((res) => {
        // console.log("solicitudes enviadas: ", res.result);
        setRequestsSent(res.result);
      });
    }
    // eslint-disable-next-line
  }, [auth.user, refreshPage]);

  React.useEffect(() => {
    if (auth.user) {
      let temp = "";
      temp = auth.user.user_display_name.split(" ");
      setUserData({
        ...userData,
        name: temp[0],
        lastName: temp[1],
      });
    }
    //eslint-disable-next-line
  }, [auth.user]);

  React.useEffect(() => {
    setEmpty(false);
    if (requestsSent.length === 0 && viewSelected === 0) {
      setEmpty(true);
    }
    if (friendRequests.length === 0 && viewSelected === 1) {
      setEmpty(true);
    }
    //eslint-disable-next-line
  }, [viewSelected, requestsSent, friendRequests]);

  return (
    <Layout>
      <div className="p-3">
        <HeaderMensajes datos={userData} />
        <BasicTabs
          tabs={DEFAULT_REQUEST_TABS}
          viewselected={viewSelected}
          setViewSelected={setViewSelected}
        />
        {viewSelected === 0
          ? requestsSent.map((e, idx) => (
              <TarjetaAmigoEnviada
                datos={e}
                key={idx}
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
              />
            ))
          : friendRequests.map((e, idx) => (
              <TarjetaAmigoRecibida
                datos={e}
                key={idx}
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
              />
            ))}
        {isEmpty && (
          <div className="d-flex text-center justify-content-center">
            <img
              src={require("../../assets/images/ico-mail.png")}
              alt="foto mensajes"
              className="img-mail"
            />
            No hay mensajes
          </div>
        )}
      </div>
    </Layout>
  );
};
