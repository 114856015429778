import { Switch } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  getEventByID,
  getUserByParam,
  createEvent,
  updateEvent,
} from "../../services/tipddy";
import { DEFAULT_EVENT } from "../../utils/state";
import { DateInput } from "../../components/DateInput";
import { useAuth } from "../../contexts/auth";
import { HeaderAgregarEvento } from "../../components/HeaderAgregarEvento";
import TextArea from "../../components/TextArea";
import TextInput from "../../components/TextInput";
import "./styles.css";
import { Loader } from "../../components/Loader";
import { SearchResults } from "../../components/SearchComponent";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import { formatDate } from "../../utils/date";
import Layout from "../../components/Layout";

export const AgregarEvento = () => {
  const [credentials, setCredentials] = React.useState(DEFAULT_EVENT);
  const [isPublic, setPublic] = React.useState(true);
  const [remember, setRemember] = React.useState(false);
  const [rememberYearly, setRememberYearly] = React.useState(false);
  const [friends, setFriends] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [eventExist, setEventExist] = React.useState(false);
  const [switchCheck, setSwitchCheck] = React.useState(null);
  const [, handleSnack] = useSnack();
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    let paramID = document.URL.split("?")[1];
    if (paramID !== undefined) {
      let eventData = async () => {
        let data = await getEventByID(paramID, auth.user.token);
        console.log(data);
        setCredentials({
          title: data.title,
          from: data.from?.ID,
          user_name: data.user_name,
          description: data.description ? data.description : "",
          place: data.place,
          direction: data.direction,
          date: new Date(formatDate(data.date)),
          optional: [...data.optional],
          gift_status: data.gift_status,
          public: data.public,
          id: paramID,
        });
        if (data.optional.length === 2) {
          setRemember(true);
          setRememberYearly(true);
        }
        if (data.optional.length === 1) {
          setRemember(true);
        }
        if (data.gift_status === "given") {
          console.log("given");
          setSwitchCheck(true);
        }
        setEventExist(true);
        return data;
      };
      eventData();
    }
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      let data = await getUserByParam(search, auth.user.token);
      if (data.length > 0) {
        setFriends([{ ID: null, display_name: search }, ...data]);
        setLoading(false);
      } else {
        setFriends([{ ID: null, display_name: search }]);
        setLoading(false);
      }
    }, 1000);
  }, [search, auth]);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "user_name") {
      setSearch(value);
    }
    setCredentials({ ...credentials, [name]: value });
    console.log({ ...credentials, [name]: value });
  };

  const handlePublic = (p) => {
    setPublic(p);
    setCredentials({ ...credentials, public: p ? "yes" : "no" });
  };

  const handleRemember = (boolean) => {
    if (!boolean) {
      setRememberYearly(false);
      setRemember(boolean);
      setCredentials({ ...credentials, optional: [] });
      return;
    }
    setRemember(boolean);
    setCredentials({ ...credentials, optional: ["remember"] });
  };

  const handleRememberYearly = (boolean) => {
    if (!remember) return;
    setRememberYearly(boolean);
    if (boolean) {
      setCredentials({ ...credentials, optional: ["remember", "everyyear"] });
    } else {
      setCredentials({ ...credentials, optional: ["remember"] });
    }
  };

  const handleSwitch = (e) => {
    if (e.target.checked) {
      setSwitchCheck(true);
      return setCredentials({ ...credentials, gift_status: "given" });
    }
    setSwitchCheck(false);
    setCredentials({ ...credentials, gift_status: "reserved" });
  };

  const handleSaveEvent = async (e) => {
    e.preventDefault();

    const isEmpty = Object.values(credentials).some((value) => value === "");

    if (isEmpty) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Complete todos los campos",
        },
      });
      return;
    }

    if (eventExist) {
      await updateEvent(credentials, auth.user.token);
      handleSnack({
        type: SNACKTYPES.success,
        payload: {
          message: "Evento actualizado con éxito",
        },
      });
    } else {
      await createEvent(credentials, auth.user.token);
      handleSnack({
        type: SNACKTYPES.success,
        payload: {
          message: "Evento creado con éxito",
        },
      });
    }

    navigate("/mi-perfil");
  };

  return (
    <Layout>
      <div className="p-3">
        <HeaderAgregarEvento />
        <section className="form-agregar-deseo">
          <TextInput
            name={"title"}
            onChange={handleChange}
            label={"Título del evento"}
            type={"text"}
            placeHolder={credentials.title}
          />
          <div style={{ position: "relative" }}>
            <TextInput
              name={"user_name"}
              onChange={handleChange}
              label={"De"}
              type={"text"}
              placeHolder={credentials.user_name}
              value={credentials.user_name}
            />
            <div>
              {search.length >= 3 && (
                <div className="search-container">
                  {!loading ? (
                    friends.map((element, idx) => (
                      <SearchResults
                        search={search}
                        friend_name={element.display_name}
                        key={idx}
                        loading={loading}
                        credentials={credentials}
                        setCredentials={setCredentials}
                        setSearch={setSearch}
                        id={element.ID}
                      />
                    ))
                  ) : (
                    <Loader visible={loading} />
                  )}
                </div>
              )}
            </div>
          </div>
          <DateInput
            label={"Fecha"}
            credentials={credentials}
            setCredentials={setCredentials}
          />
          <TextArea
            name={"description"}
            onChange={handleChange}
            label={"Descripción"}
            placeHolder={credentials.description}
          />
          <TextInput
            name={"place"}
            onChange={handleChange}
            label={"Lugar"}
            type={"text"}
            placeHolder={credentials.place}
          />
          <TextInput
            name={"direction"}
            onChange={handleChange}
            label={"Dirección"}
            type={"text"}
            placeHolder={credentials.direction}
          />
        </section>
        <section className="mover-deseo">
          <p className="font-medium">Mover evento</p>
          <div className="checklist-agregar-deseo">
            <input
              className="form-check-input baj"
              type="checkbox"
              onClick={() => handlePublic(!isPublic)}
              checked={isPublic ? true : false}
            />
            <div className="col-10">
              <p className="text-checkbox">Público</p>
            </div>
          </div>
          <div className="checklist-agregar-deseo">
            <div className="checklist-agregar-deseo col-2">
              <input
                className="form-check-input baj"
                type="checkbox"
                onClick={() => handleRemember(!remember)}
                checked={remember ? true : false}
              />
              <div className="col-10">
                <p className="text-checkbox ms-2">Recordar</p>
              </div>
            </div>
            <div className="checklist-agregar-deseo col-3">
              <input
                className="form-check-input baj"
                style={{
                  cursor: remember ? "pointer" : "not-allowed",
                }}
                type="checkbox"
                onClick={() => handleRememberYearly(!rememberYearly)}
                checked={rememberYearly ? true : false}
              />
              <div className="col-10">
                <p className="text-checkbox ms-2">Repetir todos los años</p>
              </div>
            </div>
          </div>
          <div className="checklist-agregar-deseo mt-3">
            <p className="text-checkbox ms-2 mt-2">Reservado</p>
            <Switch
              color="secondary"
              onChange={handleSwitch}
              checked={switchCheck}
            />
            <p className="text-checkbox ms-2 mt-2">Regalado</p>
          </div>

          <div
            type="button"
            className="save"
            onClick={(e) => handleSaveEvent(e)}
            style={{ maxWidth: "160px" }}
          >
            Guardar evento
          </div>
        </section>
      </div>
    </Layout>
  );
};
