import React from "react";
import ListaDeseos from "../../components/ListaDeseos";
import HeaderFichaAmigo from "../../components/HeaderFichaAmigo";
import Layout from "../../components/Layout";

const FichaAmigo = () => {
  return (
    <Layout>
      <div className="p-3">
        <HeaderFichaAmigo />
        <div className="row align-items-center tarjeta-mi-perfil">
          <div className="col-2">
            <img
              src={require("../../assets/images/usuario2.jpg")}
              alt="foto perfil personalizada"
              className="foto-perfil"
            />
          </div>
          <div className="col-6">
            <p className="nombre-tarjeta">Felipe Lopez</p>
            <p className="p-tarjeta">Cumpleaños</p>
            <p className="p-tarjeta mt-3">Faltan 187 días 07 de Octubre</p>
          </div>
          <div className="col-4">
            <p className="p-tarjeta mb-3">Datos de Envío</p>
            <button className="mis-preferencias">Mis preferencias</button>
          </div>
        </div>
        <ListaDeseos />
      </div>
    </Layout>
  );
};

export default FichaAmigo;
