import React from "react";
import { useAuth } from "../../contexts/auth";
import { HeaderMensajes } from "../../components/HeaderMensajes";
import { TarjetaMensaje } from "../../components/TarjetaMensaje";
import Layout from "../../components/Layout";
// import { listRequests } from "../../services/tipddy";

export const Mensajes = () => {
  const [userData, setUserData] = React.useState([]);
  const [msgData, setMsgData] = React.useState([
    {
      title: "¡Felicidades! Uno de tus deseos fue reservado",
    },
    {
      title: "Tienes muy pocos deseos, necesitas al menos 3 deseos",
    },
  ]);
  const auth = useAuth();

  React.useEffect(() => {
    if (auth.user) {
      let temp = "";
      temp = auth.user.user_display_name.split(" ");
      setUserData({
        ...userData,
        name: temp[0],
        lastName: temp[1],
      });
    } else {
      //este else es para no qutire el warning de setMsgData
      setMsgData([]);
    }

    // eslint-disable-next-line
  }, [auth.user]);

  return (
    <Layout>
      <div className="p-3">
        <HeaderMensajes datos={userData} />
        {msgData.length > 0 ? (
          msgData.map((e, idx) => <TarjetaMensaje datos={e} key={idx} />)
        ) : (
          <div className="d-flex text-center justify-content-center">
            <img
              src={require("../../assets/images/ico-mail.png")}
              alt="foto mensajes"
              className="img-mail"
            />
            No hay mensajes
          </div>
        )}
      </div>
    </Layout>
  );
};
