import React from "react";

const HeaderFichaAmigo = () => {
  return (
    <div className="row align-items-center my-3 ms-3 header">
      <div className="col-6 d-flex align-items-center">
        <h1 className="titulo-mi-perfil">Ficha de Amigo</h1>
        <button type="button" className="preferencias-reg ms-3 mb-2">
          <img
            src={require("../../assets/images/ico-reg.jpg")}
            alt="foto mensajes"
            className="img-mail"
          />
          ¡Con Regalo!
        </button>
      </div>
      <div className="col-3"></div>
      <div className="col-3 d-flex align-items-center justify-content-around">
        <div>Actualizar</div>
        <div type="button" onClick={() => alert("view agregar deseo")}>
          <img
            src={require("../../assets/images/ico-actualizar.png")}
            alt="imagen boton +"
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderFichaAmigo;
