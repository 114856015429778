import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#be0181",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      // color: "#fff",
      color: "#be0181",
      width: "auto",
    },
  };
}

export default function BasicTabs({ tabs, viewselected, setViewSelected }) {
  const handleChange = (event, newValue) => {
    setViewSelected(newValue);
  };

  React.useEffect(() => {
    let tabsGroup = document.querySelector(".MuiTabs-centered");

    if (tabsGroup) {
      tabsGroup.style.alignItems = "center";
    }
  }, []);

  return (
    <Box sx={{ width: "100%", margin: "2.5rem 0" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "#ccc",
          width: "100%",
        }}
      >
        <Tabs
          value={viewselected}
          onChange={handleChange}
          aria-label="basic tabs exampl"
          TabIndicatorProps={{
            style: { background: "#be0181" },
          }}
          sx={{ width: "100%" }}
          orientation={window.screen.width <= 1250 ? "vertical" : "horizontal"}
          centered={true}
        >
          {tabs &&
            tabs.map((tab, idx) => {
              return (
                <StyledTab
                  label={tab.name}
                  {...a11yProps(idx)}
                  key={idx}
                  style={{
                    margin:
                      window.screen.width <= 1250 ? ".5rem 0" : "0 0.2rem",
                    // backgroundColor: "#be0181",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    boxShadow: "3px 3px 9px #ccc",
                    borderRadius:
                      window.screen.width <= 1250 ? "10px" : "15px 15px 0 0",
                    width:
                      window.screen.width > 820 && window.screen.width < 1560
                        ? "300px"
                        : "100%",
                  }}
                />
              );
            })}
        </Tabs>
      </Box>
      <TabPanel value={viewselected} index={0}></TabPanel>
      <TabPanel value={viewselected} index={1}></TabPanel>
    </Box>
  );
}
