export const DEFAULT_LOGIN = {
  username: "",
  password: "",
};

export const DEFAULT_REGISTER = {
  password: "",
  first_name: "",
  last_name: "",
  email: "",
  origin: "desktop",
};

export const DEFAULT_WISH = {
  title: "",
  image1: null,
  image2: null,
  description: "",
  price: "",
  link: "",
  public: "yes",
};

export const DEFAULT_CHECK_CODE = {
  email: "",
  code: "",
};

export const DEFAULT_RECOVERY = {
  email: "",
  code: "",
  password: "",
};

export const DEFAULT_FORGOT = {
  email: "",
};

export const DEFAULT_PROFILE = {
  name: "",
  lastName: "",
  birthday: "",
  profileImage: "",
};

export const DEFAULT_EVENT = {
  title: "",
  from: null,
  user_name: "",
  description: "",
  place: "",
  direction: "",
  date: new Date(),
  optional: [],
  gift_status: "reserved",
  public: "yes",
};

export const DEFAULT_FRIEND_REQUEST = {
  user_id: "",
  follower_id: "",
  relation_type: "request",
  date: new Date(),
};

export const DEFAULT_FOLLOWER_DATA = {
  display_name: "",
  wishes: [],
};
